import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { updatePasswordRequest } from "../../redux/Auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import usePrevious from "../../hooks/usePrevious";

import LoaderButton from "../../components/LoaderButton/Index";
import AuthErrorMessage from "../../components/AuthErrorMessage/Index";

import "./Password.scss"

function Password({isChangePasswordModalOpen, onClose}) {

  const {
    isUpdatePasswordSuccess,
    isUpdatePasswordError,
    updatePasswordErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onTouched',
  });

  const current_password = watch('current_password');
  const new_password = watch('new_password');
  const dispatch = useDispatch();
  const prevIsUpdatePasswordSuccess = usePrevious(isUpdatePasswordSuccess);
  const prevIsUpdatePasswordError = usePrevious(isUpdatePasswordError);

  const [isCurrentPasswordShown, setIsCurrentPasswordShown] = useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false)
  const [isLoader, setIsLoader] = useState(false);
  const [passwordUpdateErrorMessage, setPasswordUpdateErrorMessage] = useState('');

  useEffect(() => {
    if (isUpdatePasswordSuccess && prevIsUpdatePasswordSuccess === false) {
      closeModal();
      setIsLoader(false);
      setPasswordUpdateErrorMessage('');
    }
  }, [isUpdatePasswordSuccess])

  useEffect(() => {
    if (isUpdatePasswordError && prevIsUpdatePasswordError === false) {
      setPasswordUpdateErrorMessage(updatePasswordErrorMessage)
      setIsLoader(false);
    }
  }, [isUpdatePasswordError])

  function closeModal() {
    onClose(false);
  }

  function handleToggleCurrentPassword() {
    setIsCurrentPasswordShown(!isCurrentPasswordShown)
  }

  function handleToggleNewPassword() {
    setIsNewPasswordShown(!isNewPasswordShown);
  }

  function handleToggleConfirmPassword() {
    setIsConfirmPasswordShown(!isConfirmPasswordShown);
  }

  function updateSubmit(data) {
    delete data.confirm_password;
    dispatch(updatePasswordRequest(data));
    setIsLoader(true);
  }

  return (
    <>
      <Modal
        className="update-password-modal"
        title={<h2 className="title">Update password</h2>}
        open={isChangePasswordModalOpen}
        onCancel={closeModal}
        footer={null}
        centered
        destroyOnClose
      >
        <form onSubmit={handleSubmit(updateSubmit)}>
          <div className="inputs">
            <div className="password-input">
              <input
                {...register('current_password',{
                  required: 'Password is required',
                  pattern: {
                    value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{8,}$/,
                    message: 'Password should include at least one letter, one numeric value and one special character'
                  },
                  minLength: {
                    value: 8,
                    message: 'Minimum required length is 8'
                  },
                  maxLength: {
                    value: 64,
                    message: 'Maximum required length is 64'
                  }
                })}
                type={isCurrentPasswordShown ? 'text' : 'password'}
                placeholder="Current password"
                className={errors?.current_password ? 'input-error' : ''}
              />
              <div className="eye-icon" onClick={handleToggleCurrentPassword}>
                {isCurrentPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
              </div>
            </div>
            { errors?.current_password && <div className="error-message">{errors?.current_password?.message || 'Field is required'}</div> }
          </div>
          <div className="inputs">
            <div className="password-input">
              <input
                {...register('new_password',{
                  required: 'Password is required',
                  validate: (value) =>
                    value !== current_password || 'Please choose a new password',
                  pattern: {
                    value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{8,}$/,
                    message: 'Password should include at least one letter, one numeric value and one special character'
                  },
                  minLength: {
                    value: 8,
                    message: 'Minimum required length is 8'
                  },
                  maxLength: {
                    value: 64,
                    message: 'Maximum required length is 64'
                  }
                })}
                type={isNewPasswordShown ? 'text' : 'password'}
                placeholder="New password"
                className={errors?.new_password ? 'input-error' : ''}
              />
              <div className="eye-icon" onClick={handleToggleNewPassword}>
                {isNewPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
              </div>
            </div>
            { errors?.new_password && <div className="error-message">{errors?.new_password?.message || 'Field is required'}</div> }
          </div>
          <div className="inputs">
            <div className="password-input">
              <input
                {...register('confirm_password',{
                  required: 'Confirm Password is required',
                  validate: (value) =>
                    value === new_password || 'The passwords do not match'
                })}
                type={isConfirmPasswordShown ? 'text' : 'password'}
                placeholder="Confirm password"
                onPaste={(e) => {
                  e.preventDefault()
                  return false;
                }}
                className={errors?.confirm_password ? 'input-error' : ''}
              />
              <div className="eye-icon" onClick={handleToggleConfirmPassword}>
                {isConfirmPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
              </div>
            </div>
            { errors?.confirm_password && <div className="error-message">{errors?.confirm_password?.message || 'Field is required'}</div> }
          </div>
          { passwordUpdateErrorMessage &&
              <AuthErrorMessage errorMessage={passwordUpdateErrorMessage} />
          }
          <div className="button">
            {
              isLoader ?
                <LoaderButton buttonText="Update" /> :
                <input type="submit" value="Update" />
            }
          </div>
        </form>
      </Modal>
    </>
  )
}

export default Password;
