import React from "react";

import "./Index.scss"

function AuthErrorMessage({errorMessage}) {

  return (
    <div className="auth-error-message">
      <span>{errorMessage}</span>
    </div>
  )
}

export default AuthErrorMessage;
