export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'

export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST'
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS'
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR'

export const AUTH_CONFIRM_REQUEST = 'AUTH_CONFIRM_REQUEST'
export const AUTH_CONFIRM_SUCCESS = 'AUTH_CONFIRM_SUCCESS'
export const AUTH_CONFIRM_ERROR = 'AUTH_CONFIRM_ERROR'

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS'
export const CURRENT_USER_ERROR = 'CURRENT_USER_ERROR'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const CONFIRM_RESET_PASSWORD_REQUEST = 'CONFIRM_RESET_PASSWORD_REQUEST'
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS'
export const CONFIRM_RESET_PASSWORD_ERROR = 'CONFIRM_RESET_PASSWORD_ERROR'

export const UPDATE_NAME_REQUEST = 'UPDATE_NAME_REQUEST'
export const UPDATE_NAME_SUCCESS = 'UPDATE_NAME_SUCCESS'
export const UPDATE_NAME_ERROR = 'UPDATE_NAME_ERROR'

export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR'

export const CONFIRM_UPDATE_EMAIL_REQUEST = 'CONFIRM_UPDATE_EMAIL_REQUEST'
export const CONFIRM_UPDATE_EMAIL_SUCCESS = 'CONFIRM_UPDATE_EMAIL_SUCCESS'
export const CONFIRM_UPDATE_EMAIL_ERROR = 'CONFIRM_UPDATE_EMAIL_ERROR'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR'

export const CONFIRM_DELETE_ACCOUNT_REQUEST = 'CONFIRM_DELETE_ACCOUNT_REQUEST'
export const CONFIRM_DELETE_ACCOUNT_SUCCESS = 'CONFIRM_DELETE_ACCOUNT_SUCCESS'
export const CONFIRM_DELETE_ACCOUNT_ERROR = 'CONFIRM_DELETE_ACCOUNT_ERROR'

export const GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST'
export const GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS'
export const GET_SUBSCRIPTION_PLAN_ERROR = 'GET_SUBSCRIPTION_PLAN_ERROR'
export const GET_SUBSCRIPTION_PLAN_NAME_REQUEST = 'GET_SUBSCRIPTION_PLAN_NAME_REQUEST'
export const GET_SUBSCRIPTION_PLAN_NAME_SUCCESS = 'GET_SUBSCRIPTION_PLAN_NAME_SUCCESS'
export const RESET_SUBSCRIPTION_PLAN = 'RESET_SUBSCRIPTION_PLAN'


export const DEVICE_LOGOUT_REQUEST = 'DEVICE_LOGOUT_REQUEST'
export const ALL_DEVICES_LOGOUT_REQUEST = 'ALL_DEVICES_LOGOUT_REQUEST'

export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'