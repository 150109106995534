import React from "react";

import { Modal } from 'antd';
import { Button } from 'antd';

import { useNavigate } from "react-router-dom"

import "./index.scss"

function UpgradePlan({ isUpgradePlanModalOpen, currentSubscriptionPlan = 'Free', subscriptionLimitErrorMessage = '', isNavigationDisabled = false, onClose }) {

  const navigate = useNavigate();

  function closeModal() {
    onClose(false);
  }

  function toSubscription() {
    navigate("/workspace/subscription");
  }

  return (
    <>
      <Modal
        className="upgrade-plan-modal"
        title={
          <div>
            {subscriptionLimitErrorMessage && <h2 className="title error">{subscriptionLimitErrorMessage}</h2>}
            <h3 className="title">Your current subscription plan: {currentSubscriptionPlan}</h3>
          </div>
        }
        width="50%"
        centered
        open={isUpgradePlanModalOpen}
        onCancel={closeModal}
        footer={null}
      >
        <div className="upgrade-plan-content">
          <h3 className="upgrade-plan-content-title">How to Activate Your Subscription Plan</h3>
          <ol>
            <li><span className="upgrade-plan-content-bold-text">Choose Your Plan</span>: Select the subscription plan that best suits your needs from our options.</li>
            <li><span className="upgrade-plan-content-bold-text">Contact Us</span>: Reach out to us via your preferred method —
              +37493058388(Phone), talk2edit.help@gmail.com(Email), <a href="https://www.facebook.com/profile.php?id=61562177254598" target="_blank">Facebook</a> or <a href="https://www.instagram.com/talk2edit/" target="_blank">Instagram</a>.</li>
            <li><span className="upgrade-plan-content-bold-text">Share Your Details</span>: Provide the necessary information, including your chosen plan and any relevant details we might need.</li>
            <li><span className="upgrade-plan-content-bold-text">Make Payment</span>: Complete the payment process as directed by our team.</li>
            <li><span className="upgrade-plan-content-bold-text">Get Your Plan</span>: Once the payment is confirmed, we'll activate your subscription plan promptly.</li>
          </ol>
          <p>If you have any questions or need assistance, feel free to contact us. We're here to help!</p>
        </div>
        {
          !isNavigationDisabled &&
            <div className="upgrade-plan-button">
              <Button type="primary" onClick={toSubscription}>Get Subscription</Button>
            </div>
        }
      </Modal>
    </>
  )
}

export default UpgradePlan;
