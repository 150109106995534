import React from "react";
import { Modal } from "antd";

function RemoveDocument({ isDeleteDocumentModalOpen, document_title = '', onRemove, onClose }) {

  function deleteDocument() {
    onRemove();
    closeModal();
  }

  function closeModal() {
    onClose(false);
  }

  return (
    <>
      <Modal
        className="confirm-email-update-modal"
        title={<h2 className="title">Delete Document</h2>}
        open={isDeleteDocumentModalOpen}
        onCancel={closeModal}
        okText="Yes, delete"
        onOk={deleteDocument}
        centered
      >
        <p>Are you sure you want to delete the file "{document_title}" ?</p>
      </Modal>
    </>
  )
}

export default RemoveDocument;
