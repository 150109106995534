import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDocumentRequest, fetchDocumentsRequest, resetDocumentsList } from "../../redux/Document/actions";
import { useNavigate } from "react-router-dom"
import usePrevious from "../../hooks/usePrevious";

import Document from "../Document/Index";
import UpgradePlan from "../../modals/UpgradePlan";
import Loader from "../Loader";

import { MdNoteAdd } from "react-icons/md";
import { Input } from 'antd';

import "./Dashboard.scss";

function Dashboard() {

  const {
    getSubscriptionPlan
  } = useSelector(state => state.auth)
  const {
    isGetDocumentsListSuccess, isGetDocumentsListError, getDocumentsListErrorMessage, fetchDocuments,
    isCreateDocumentSuccess, isCreateDocumentError, createDocumentErrorMessage, createDocument,
    isDeleteDocumentSuccess, isDeleteDocumentError, deleteDocumentErrorMessage, deleteDocument,
  } = useSelector(state => state.document)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevIsCreateDocumentSuccess = usePrevious(isCreateDocumentSuccess);
  const prevIsCreateDocumentError = usePrevious(isCreateDocumentError);
  const [allDocuments, setAllDocuments] = useState([])
  const [documentId, setDocumentId] = useState(0);
  const [searchDocument, setSearchDocument] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [subscriptionLimitErrorMessage, setSubscriptionLimitErrorMessage] = useState('');
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchDocumentsRequest());

    return () => {
      dispatch(resetDocumentsList());
    }

  }, [])

  useEffect(() => {
    if (isGetDocumentsListSuccess && getSubscriptionPlan) {
      setIsLoader(false);
    }
  }, [isGetDocumentsListSuccess, getSubscriptionPlan])

  useEffect(() => {
    if (isGetDocumentsListSuccess) {
      setAllDocuments(fetchDocuments.documents);
      setFilteredDocuments(fetchDocuments.documents);
    }
  }, [isGetDocumentsListSuccess])

  useEffect(() => {
    if (isCreateDocumentSuccess && prevIsCreateDocumentSuccess === false) {
      toNewDocument(createDocument.document_id);
    }
  }, [isCreateDocumentSuccess])

  useEffect(() => {
    if (isCreateDocumentError && prevIsCreateDocumentError === false) {
      toggleUpgradePlanModalOpen(true)
      setSubscriptionLimitErrorMessage(createDocumentErrorMessage);
    }
  }, [isCreateDocumentError])

  useEffect(() => {
    if (isDeleteDocumentSuccess && documentId) {
      const removeDocument = allDocuments.filter(({ document_id }) => document_id !== documentId);
      setAllDocuments(removeDocument);
      setFilteredDocuments(removeDocument);
      setDocumentId(0);
    }
  }, [isDeleteDocumentSuccess, documentId])

  function getNewId() {
    dispatch(createDocumentRequest())
  }

  function toNewDocument(id) {
    navigate(`/document-details/${id}`)
  }

  function removeId(id) {
    setDocumentId(id)
  }

  function handleChange({target: {value}}) {
    setSearchDocument(value)
    const newFilteredDocuments = allDocuments.filter(({ document_title }) => document_title.toLowerCase().includes(value.toLowerCase()));
    setFilteredDocuments(newFilteredDocuments);
  }

  function toggleUpgradePlanModalOpen(isOpen) {
    setIsUpgradePlanModalOpen(isOpen)
  }

  return (
    <>
      {isLoader ?
        <Loader /> :
        <div className="dashboard">
          <div className="search">
            <Input
              type="text"
              placeholder="Search..."
              value={searchDocument}
              onChange={handleChange}
            />
          </div>
          <div className="documents-container">
            <div className="documents">
              <div className="add-new" onClick={getNewId}>
                <div className="content">
                  <MdNoteAdd className="add-icon" />
                  <p>New</p>
                </div>
                <div className="bottom">
                </div>
              </div>
            </div>
            {filteredDocuments?.map(({ document_id, document_title }) => (
              <div className="documents" key={document_id}>
                <Document document_id={document_id} document_title={document_title} onRemoveId={removeId} />
              </div>
            ))}
          </div>
          <UpgradePlan isUpgradePlanModalOpen={isUpgradePlanModalOpen} currentSubscriptionPlan={getSubscriptionPlan} subscriptionLimitErrorMessage={subscriptionLimitErrorMessage} onClose={toggleUpgradePlanModalOpen} />
        </div>
      }
    </>
  )
}

export default Dashboard;
