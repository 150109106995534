import {
  AUTH_CONFIRM_ERROR,
  AUTH_CONFIRM_REQUEST,
  AUTH_CONFIRM_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  CONFIRM_RESET_PASSWORD_REQUEST,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CURRENT_USER_ERROR,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UPDATE_NAME_ERROR,
  UPDATE_NAME_REQUEST,
  UPDATE_NAME_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  CONFIRM_UPDATE_EMAIL_REQUEST,
  CONFIRM_UPDATE_EMAIL_SUCCESS,
  CONFIRM_UPDATE_EMAIL_ERROR,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_SUCCESS,
  CONFIRM_DELETE_ACCOUNT_REQUEST,
  CONFIRM_DELETE_ACCOUNT_SUCCESS,
  CONFIRM_DELETE_ACCOUNT_ERROR,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_ERROR,
  GET_SUBSCRIPTION_PLAN_NAME_REQUEST,
  GET_SUBSCRIPTION_PLAN_NAME_SUCCESS,
  RESET_SUBSCRIPTION_PLAN,
} from "./types";

const initialState = {
  isAuthLoginSuccess: false,
  isAuthLoginError: false,
  authLoginErrorMessage: '',
  authLogin: {},
  isAuthRegisterSuccess: false,
  isAuthRegisterError: false,
  authRegisterErrorMessage: '',
  authRegister: {},
  isAuthConfirmSuccess: false,
  isAuthConfirmError: false,
  authConfirmErrorMessage: '',
  isCurrentUserSuccess: false,
  isCurrentUserError: false,
  currentUserErrorMessage: '',
  currentUser: {},
  isResetPasswordSuccess: false,
  isResetPasswordError: false,
  resetPasswordErrorMessage: '',
  resetPassword: {},
  isConfirmResetPasswordSuccess: false,
  isConfirmResetPasswordError: false,
  confirmResetPasswordErrorMessage: '',
  confirmResetPassword: {},
  isUpdateNameSuccess: false,
  isUpdateNameError: false,
  updateNameErrorMessage: '',
  isUpdateEmailSuccess: false,
  isUpdateEmailError: false,
  updateEmailErrorMessage: '',
  isConfirmUpdateEmailSuccess: false,
  isConfirmUpdateEmailError: false,
  confirmUpdateEmailErrorMessage: '',
  isUpdatePasswordSuccess: false,
  isUpdatePasswordError: false,
  updatePasswordErrorMessage: '',
  isDeleteAccountSuccess: false,
  isDeleteAccountError: false,
  deleteAccountErrorMessage: '',
  isConfirmDeleteAccountSuccess: false,
  isConfirmDeleteAccountError: false,
  confirmDeleteAccountErrorMessage: '',
  confirmDeleteAccount: '',
  isGetSubscriptionPlanSuccess: false,
  isGetSubscriptionPlanError: false,
  getSubscriptionPlanErrorMessage: '',
  fetchSubscriptionPlan: {},
  getSubscriptionPlan: '',
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isAuthLoginSuccess: false,
        isAuthLoginError: false,
        authLoginErrorMessage: '',
        authLogin: {},
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthLoginSuccess: true,
        isAuthLoginError: false,
        authLoginErrorMessage: '',
        authLogin: action.payload,
      }
    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        isAuthLoginSuccess: false,
        isAuthLoginError: true,
        authLoginErrorMessage: action.payload,
        authLogin: {},
      }

    case AUTH_REGISTER_REQUEST:
      return {
        ...state,
        isAuthRegisterSuccess: false,
        isAuthRegisterError: false,
        authRegisterErrorMessage: '',
        authRegister: {},
      }
    case AUTH_REGISTER_SUCCESS:
      return {
        ...state,
        isAuthRegisterSuccess: true,
        isAuthRegisterError: false,
        authRegisterErrorMessage: '',
        authRegister: action.payload,
      }
    case AUTH_REGISTER_ERROR:
      return {
        ...state,
        isAuthRegisterSuccess: false,
        isAuthRegisterError: true,
        authRegisterErrorMessage: action.payload,
        authRegister: {},
      }

    case AUTH_CONFIRM_REQUEST:
      return {
        ...state,
        isAuthConfirmSuccess: false,
        isAuthConfirmError: false,
        authConfirmErrorMessage: '',
      }
    case AUTH_CONFIRM_SUCCESS:
      return {
        ...state,
        isAuthConfirmSuccess: true,
        isAuthConfirmError: false,
        authConfirmErrorMessage: '',
      }
    case AUTH_CONFIRM_ERROR:
      return {
        ...state,
        isAuthConfirmSuccess: false,
        isAuthConfirmError: true,
        authConfirmErrorMessage: action.payload,
      }

    case CURRENT_USER_REQUEST:
      return {
        ...state,
        isCurrentUserSuccess: false,
        isCurrentUserError: false,
        currentUserErrorMessage: '',
        currentUser: {},
      }
    case CURRENT_USER_SUCCESS:
      return {
        ...state,
        isCurrentUserSuccess: true,
        isCurrentUserError: false,
        currentUserErrorMessage: '',
        currentUser: action.payload,
      }
    case CURRENT_USER_ERROR:
      return {
        ...state,
        isCurrentUserSuccess: false,
        isCurrentUserError: true,
        currentUserErrorMessage: action.payload,
        currentUser: {},
      }

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordSuccess: false,
        isResetPasswordError: false,
        resetPasswordErrorMessage: '',
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordSuccess: true,
        isResetPasswordError: false,
        resetPasswordErrorMessage: '',
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isResetPasswordSuccess: false,
        isResetPasswordError: true,
        resetPasswordErrorMessage: action.payload,
      }

    case CONFIRM_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isConfirmResetPasswordSuccess: false,
        isConfirmResetPasswordError: false,
        confirmResetPasswordErrorMessage: '',
      }
    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isConfirmResetPasswordSuccess: true,
        isConfirmResetPasswordError: false,
        confirmResetPasswordErrorMessage: '',
      }
    case CONFIRM_RESET_PASSWORD_ERROR:
      return {
        ...state,
        isConfirmResetPasswordSuccess: false,
        isConfirmResetPasswordError: true,
        confirmResetPasswordErrorMessage: action.payload,
      }

    case UPDATE_NAME_REQUEST:
      return {
        ...state,
        isUpdateNameSuccess: false,
        isUpdateNameError: false,
        updateNameErrorMessage: '',
      }
    case UPDATE_NAME_SUCCESS:
      return {
        ...state,
        isUpdateNameSuccess: true,
        isUpdateNameError: false,
        updateNameErrorMessage: '',
      }
    case UPDATE_NAME_ERROR:
      return {
        ...state,
        isUpdateNameSuccess: false,
        isUpdateNameError: true,
        updateNameErrorMessage: action.payload,
      }

    case UPDATE_EMAIL_REQUEST:
      return {
        ...state,
        isUpdateEmailSuccess: false,
        isUpdateEmailError: false,
        updateEmailErrorMessage: '',
      }
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        isUpdateEmailSuccess: true,
        isUpdateEmailError: false,
        updateEmailErrorMessage: '',
      }
    case UPDATE_EMAIL_ERROR:
      return {
        ...state,
        isUpdateEmailSuccess: false,
        isUpdateEmailError: true,
        updateEmailErrorMessage: action.payload,
      }

    case CONFIRM_UPDATE_EMAIL_REQUEST:
      return {
        ...state,
        isConfirmUpdateEmailSuccess: false,
        isConfirmUpdateEmailError: false,
        confirmUpdateEmailErrorMessage: '',
      }
    case CONFIRM_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        isConfirmUpdateEmailSuccess: true,
        isConfirmUpdateEmailError: false,
        confirmUpdateEmailErrorMessage: '',
      }
    case CONFIRM_UPDATE_EMAIL_ERROR:
      return {
        ...state,
        isConfirmUpdateEmailSuccess: false,
        isConfirmUpdateEmailError: true,
        confirmUpdateEmailErrorMessage: action.payload,
      }

    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isUpdatePasswordSuccess: false,
        isUpdatePasswordError: false,
        updatePasswordErrorMessage: '',
      }
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdatePasswordSuccess: true,
        isUpdatePasswordError: false,
        updatePasswordErrorMessage: '',
      }
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        isUpdatePasswordSuccess: false,
        isUpdatePasswordError: true,
        updatePasswordErrorMessage: action.payload,
      }

    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isDeleteAccountSuccess: false,
        isDeleteAccountError: false,
        deleteAccountErrorMessage: '',
      }
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isDeleteAccountSuccess: true,
        isDeleteAccountError: false,
        deleteAccountErrorMessage: '',
      }
    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        isDeleteAccountSuccess: false,
        isDeleteAccountError: true,
        deleteAccountErrorMessage: action.payload,
      }

    case CONFIRM_DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isConfirmDeleteAccountSuccess: false,
        isConfirmDeleteAccountError: false,
        confirmDeleteAccountErrorMessage: '',
        confirmDeleteAccount: '',
      }
    case CONFIRM_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isConfirmDeleteAccountSuccess: true,
        isConfirmDeleteAccountError: false,
        confirmDeleteAccountErrorMessage: '',
        confirmDeleteAccount: action.payload,
      }
    case CONFIRM_DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        isConfirmDeleteAccountSuccess: false,
        isConfirmDeleteAccountError: true,
        confirmDeleteAccountErrorMessage: action.payload,
        confirmDeleteAccount: '',
      }

    case GET_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        isGetSubscriptionPlanSuccess: false,
        isGetSubscriptionPlanError: false,
        getSubscriptionPlanErrorMessage: '',
        fetchSubscriptionPlan: {},
      }
    case GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        isGetSubscriptionPlanSuccess: true,
        isGetSubscriptionPlanError: false,
        getSubscriptionPlanErrorMessage: '',
        fetchSubscriptionPlan: action.payload,
      }
    case GET_SUBSCRIPTION_PLAN_ERROR:
      return {
        ...state,
        isGetSubscriptionPlanSuccess: false,
        isGetSubscriptionPlanError: true,
        getSubscriptionPlanErrorMessage: action.payload,
        fetchSubscriptionPlan: {},
      }
    case GET_SUBSCRIPTION_PLAN_NAME_REQUEST:
      return {
        ...state,
        getSubscriptionPlan: '',
      }
    case GET_SUBSCRIPTION_PLAN_NAME_SUCCESS:
      return {
        ...state,
        getSubscriptionPlan: action.payload,
      }
    case RESET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        isGetSubscriptionPlanSuccess: false,
        isGetSubscriptionPlanError: false,
        getSubscriptionPlanErrorMessage: '',
        fetchSubscriptionPlan: {},
      }

    default: return state
  }
}
