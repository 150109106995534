import React from "react";
import { Button } from "antd";

import "./Index.scss";

function LoaderButton({ buttonText = '' }) {
  return (
    <div className="loader-button-container">
      <Button className="loader-button" loading>{buttonText}</Button>
    </div>
  )
}

export default LoaderButton;
