import React from "react";
import { useNavigate } from "react-router-dom";

import "./Index.scss";

function Product() {

  const navigate = useNavigate()

  function toLogin() {
    navigate("/login")
  }

  return (
    <div className="product">
      <div className="product-content">
        <div className="product-section">
          <h1>Transform Your Voice into Text with Our Advanced Speech Recognition System</h1>
          <p>Welcome to our innovative web application, where cutting-edge technology meets convenience and efficiency. Our platform offers seamless document editing by voice,
            utilizing a sophisticated speech recognition system that supports two languages: Armenian and English.</p>
        </div>
        <div className="product-section">
          <div className="button" onClick={toLogin}>Sign in</div>
        </div>
        <div className="product-section">
          <h2>Key Features</h2>
        </div>
        <div className="product-section">
          <h2>Multilingual Speech Recognition</h2>
          <ul>
            <li><span className="product-section-bold-text">Two Languages</span>: Effortlessly switch between Armenian and English.</li>
            <li><span className="product-section-bold-text">Accurate Transcriptions</span>: Enjoy precise voice-to-text conversion in your preferred language.</li>
          </ul>
        </div>
        <div className="product-section">
          <h2>Express Transcription Service</h2>
          <ul>
            <li><span className="product-section-bold-text">Supported Formats</span>: Transcribe audio files (mp3, wav), video files (mp4), and live microphone streams.</li>
            <li><span className="product-section-bold-text">Fast and Efficient</span>: Our lightweight models ensure quick and reliable transcription, saving you valuable time.</li>
          </ul>
        </div>
        <div className="product-section">
          <h2>Enhanced Transcription Capabilities</h2>
          <ul>
            <li><span className="product-section-bold-text">Punctuation and Capitalization</span>: Optional features to enhance the readability and clarity of your transcriptions.</li>
            <li><span className="product-section-bold-text">Scientific Excellence</span>: Our Armenian ASR model is a product of the renowned ArmSpeech project, showcasing the latest advancements in speech recognition research.</li>
          </ul>
        </div>
        <div className="product-section">
          Experience the future of document editing and transcription with our state-of-the-art web application. Whether you need to transcribe a meeting, create written content from spoken words, or edit documents by voice, our platform is designed to meet your needs with speed and accuracy.
        </div>
      </div>
    </div>
  )
}

export default Product;
