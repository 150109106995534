import React, { useEffect, useRef, useState } from "react";

import { Input, Button, Progress } from 'antd';

import { io } from "socket.io-client";
import LanguageDropdown from "../LanguageDropdown/Index";
import Switch from "react-switch";
import ArmenianFlag from "../../assets/images/Armenia.png";
import UkFlag from "../../assets/images/UK.png";
import { toast } from "react-toastify";
import UpgradePlan from "../../modals/UpgradePlan";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { BsFiletypeTxt } from "react-icons/bs";
import { downloadTxtRequest } from "../../redux/Document/actions";
import usePrevious from "../../hooks/usePrevious";

import "./Index.scss";

function Youtube() {

  const languageData = [
    { id: 1, languageName: 'Armenian', languageFlag: ArmenianFlag, languageSlug: 'HY' },
    { id: 2, languageName: 'English', languageFlag: UkFlag, languageSlug: 'EN' },
    // { id: 3, languageName: 'Russian', languageFlag: RussianFlag, languageSlug: 'RU' }
  ];
  const defaultSelected = languageData[0];
  const {
    getSubscriptionPlan
  } = useSelector(state => state.auth)
  const {
    isDownloadTxtSuccess,
    isDownloadTxtError,
    downloadTxtErrorMessage,
    downloadTxt,
  } = useSelector(state => state.document)
  const dispatch = useDispatch();
  const prevIsDownloadTxtSuccess = usePrevious(isDownloadTxtSuccess)
  const socketRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [text, setText] = useState('');
  const [convertLink, setConvertLink] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(defaultSelected);
  const [selectedFilters, setSelectedFilters] = useState({is_punct_capit: false})
  const [subscriptionLimitErrorMessage, setSubscriptionLimitErrorMessage] = useState('');
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [isRecordTranscribeSuccess, setIsRecordTranscribeSuccess] = useState(false);
  const [isCopyButtonShown, setIsCopyButtonShown] = useState(false)
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {

    const handleBeforeUnload = (event) => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    }
  }, [])

  useEffect(() => {
    if (isDownloadTxtSuccess && prevIsDownloadTxtSuccess === false) {
      downloadDocument(downloadTxt);
    }
  }, [isDownloadTxtSuccess]);

  function sendLink() {
    setIsLoading(true);
    const token = localStorage.token;
    const { is_punct_capit } = selectedFilters;
    const { languageSlug } = selectedLanguage;
    const dataToSend = {
      token,
      youtube_link: convertLink,
      is_punct_capit,
      language: languageSlug,
    };

    //socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {timeout: 86400, reconnectionDelay: 14400, reconnectionDelayMax: 43200});

    socketRef.current.on('connect', () => {
      socketRef.current.emit('start_transcribe_youtube', dataToSend);
      setText('');
      setSubscriptionLimitErrorMessage('');
      setIsRecordTranscribeSuccess(false);
      setIsCopyButtonShown(false);
    });

    socketRef.current.on('transcribe_error', (data) => {
      toast.error(data.message)
    });

    socketRef.current.on('limit_error', (data) => {
      toggleUpgradePlanModalOpen(true)
      setSubscriptionLimitErrorMessage(data.message);
    });

    socketRef.current.on('link_error', (data) => {
      toast.error(data.message)
    });

    socketRef.current.on('transcribe_progress', (data) => {
      if (data.percent === -1) {
        toast.error(data.transcription)
      } else {
        const percent = +data.percent.toFixed(2);
        setProgress(percent);
        setText(prev => prev ? `${prev} ${data.transcription}` : data.transcription);
        // setText(prev => {
        //  const shouldAddSpace = prev && !data.transcription.startsWith(',');
        //  return shouldAddSpace ? `${prev} ${data.transcription}` : `${prev}${data.transcription}`;
        //});
        setIsRecordTranscribeSuccess(true);
      }
    });

    socketRef.current.on('disconnect', () => {
      setTimeout(() => {
        setProgress(0);
        setIsLoading(false);
        setIsCopyButtonShown(true)
        socketRef.current.disconnect();
      }, 1000)
    });
  }

  function changeLanguage(language) {
    setSelectedLanguage(language)
  }

  function inputChangeHandler(checked) {
    setSelectedFilters({...selectedFilters, is_punct_capit: checked})
  }

  function handleChange({target: {value}}) {
    setConvertLink(value)
  }

  function onCopy() {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500)
  }

  function convert() {
    const obj = { transcription: text }
    dispatch(downloadTxtRequest(obj));
  }

  function downloadDocument(downloadLink) {
    const documentBlob = new Blob([downloadLink], { type: 'text/plain' });
    const url = window.URL.createObjectURL(documentBlob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'Untitled.txt';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url)
  }

  function toggleUpgradePlanModalOpen(isOpen) {
    setIsUpgradePlanModalOpen(isOpen)
  }

  return (
    <div className="youtube">
      <div className="youtube-filters">
        <div className="language-drop-down">
          <LanguageDropdown items={languageData} defaultSelected={selectedLanguage} onChangeItem={changeLanguage} />
        </div>
        <span onClick={() => inputChangeHandler(!selectedFilters.is_punct_capit)}>Grammar</span>
        <Switch onChange={inputChangeHandler} checked={selectedFilters.is_punct_capit} className="react-switch"/>
      </div>
      <div className="youtube-convert">
        <div className="youtube-convert-input">
          <Input
            type="text"
            placeholder="Youtube link..."
            value={convertLink}
            onChange={handleChange}
          />
        </div>
        <div className="youtube-convert-button">
          <Button type="primary" onClick={sendLink}>Convert</Button>
        </div>
      </div>
      {isLoading ?
        <div className="youtube-loader-container">
          <Progress type="circle" percent={progress} />
        </div> : null
      }
      {isRecordTranscribeSuccess &&
        <div className="youtube-response-data">
          {text &&
            <>
              <h2 className="resp-text">{text}</h2>
              {isCopyButtonShown ?
                <div className="copy-button-container">
                  <CopyToClipboard text={text} onCopy={onCopy}>
                    <Button
                      type="primary"
                      icon={isCopied ? <CheckOutlined className="copy-button-icons" /> : <CopyOutlined className="copy-button-icons" />}
                      size="large"
                      className="copy-button"
                    >
                      {isCopied ? 'Copied' : 'Copy'}
                    </Button>
                  </CopyToClipboard>
                  <BsFiletypeTxt className="download-icon" onClick={convert} />
                </div> : null
              }
            </>
          }
          {!text && progress === 100 && <h2 className="error-text">NO SPEECH DETECTED</h2>}
        </div>
      }
      <UpgradePlan isUpgradePlanModalOpen={isUpgradePlanModalOpen} currentSubscriptionPlan={getSubscriptionPlan} subscriptionLimitErrorMessage={subscriptionLimitErrorMessage} onClose={toggleUpgradePlanModalOpen} />
    </div>
  )
}

export default Youtube;