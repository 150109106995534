import { put, takeLatest } from "redux-saga/effects"
import {
  ALL_DEVICES_LOGOUT_REQUEST,
  AUTH_CONFIRM_REQUEST,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGOUT_REQUEST,
  AUTH_REGISTER_REQUEST,
  CONFIRM_DELETE_ACCOUNT_REQUEST,
  CONFIRM_RESET_PASSWORD_REQUEST,
  CONFIRM_UPDATE_EMAIL_REQUEST,
  CURRENT_USER_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  DEVICE_LOGOUT_REQUEST,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_NAME_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_EMAIL_REQUEST,
  UPDATE_NAME_REQUEST,
  UPDATE_PASSWORD_REQUEST
} from "./types";
import axios from "axios";
import {
  authConfirmError,
  authConfirmSuccess,
  authLoginError,
  authLoginSuccess,
  authRegisterError,
  authRegisterSuccess,
  confirmDeleteAccountError,
  confirmDeleteAccountSuccess,
  confirmResetPasswordError,
  confirmResetPasswordSuccess,
  confirmUpdateEmailError,
  confirmUpdateEmailSuccess,
  currentUserError,
  currentUserRequest,
  currentUserSuccess,
  deleteAccountError,
  deleteAccountSuccess,
  fetchSubscriptionPlanError,
  fetchSubscriptionPlanSuccess,
  getSubscriptionPlanNameRequest,
  getSubscriptionPlanNameSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  updateEmailError,
  updateEmailSuccess,
  updateNameError,
  updateNameSuccess,
  updatePasswordError,
  updatePasswordSuccess,
} from "./actions";

let token = null;
let user;

function* authRegister({payload}) {
  try {
    yield axios.post('/signup', payload)
    yield put(authRegisterSuccess())
  } catch (err) {
    yield put(authRegisterError(err.response.data));
  }
}

function* authConfirm({payload}) {
  try {
    yield axios.post('/confirm_signup', payload)
    yield put(authConfirmSuccess())
  } catch (err) {
    yield put(authConfirmError(err.response.data))
  }
}

function* authLogin({payload}) {
  try {
    const response = yield axios.post('/login', payload)
    yield put(authLoginSuccess(response.data))
    yield put(currentUserRequest())
    yield put(getSubscriptionPlanNameRequest())
    setToken(response.data)
    localStorage.setItem('token', response.data)
  } catch (err) {
    yield put(authLoginError(err.response.data));
  }
}

function* currentUser() {
  try {
    const response = yield axios.get('/me')
    user = response.data;
    yield put(currentUserSuccess(response.data))
  } catch (err) {
    yield put(currentUserError(err))
  }
}

function* resetPassword({payload}) {
  try {
    yield axios.post('/reset_password', payload);
    yield put(resetPasswordSuccess())
  } catch (err) {
    yield put(resetPasswordError(err.response.data))
  }
}

function* confirmResetPassword({payload}) {
  try {
    yield axios.post('/confirm_reset_password', payload)
    yield put(confirmResetPasswordSuccess())
  } catch (err) {
    yield put(confirmResetPasswordError(err.response.data))
  }
}

function* updateFullName({payload}) {
  try {
    yield axios.post('/update_full_name', payload);
    yield put(updateNameSuccess())
  } catch (err) {
    yield put(updateNameError(err.response.data));
  }
}

function* updateEmail({payload}) {
  try {
    yield axios.post('/update_email', payload)
    yield put(updateEmailSuccess())
  } catch (err) {
    yield put(updateEmailError(err.response.data))
  }
}

function* confirmUpdateEmail({payload}) {
  try {
    yield axios.post('/confirm_update_email', payload)
    yield put(confirmUpdateEmailSuccess())
  } catch (err) {
    yield put(confirmUpdateEmailError(err.response.data))
  }
}

function* updatePassword({payload}) {
  try {
    yield axios.post('/update_password', payload)
    yield put(updatePasswordSuccess())
  } catch (err) {
    yield put(updatePasswordError(err.response.data))
  }
}

function* deleteAccount() {
  try {
    yield axios.get('/delete_account')
    yield put(deleteAccountSuccess())
  } catch (err) {
    yield put(deleteAccountError(err.response.data))
  }
}

function* confirmDeleteAccount({payload}) {
  try {
    const response = yield axios.post('/confirm_delete_account', payload)
    yield put(confirmDeleteAccountSuccess(response.data))
  } catch (err) {
    yield put(confirmDeleteAccountError(err.response.data))
  }
}

function* fetchSubscriptionPlan() {
  try {
    const response =  yield axios.get('/get_subscription_plan')
    yield put(fetchSubscriptionPlanSuccess(response.data))
  } catch (err) {
    yield put(fetchSubscriptionPlanError(err.response.data))
  }
}

function* getSubscriptionPlanName() {
  try {
    const response = yield axios.get('/get_subscription_plan')
    yield put(getSubscriptionPlanNameSuccess(response.data.plan))
  } catch (err) {
    console.log(err);
  }
}

function* signOutDevice({payload}) {
  try {
    yield axios.post('/logout_device', payload)
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
}

function* signOutAllDevices() {
  try {
    yield axios.get('/logout_all_devices')
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
}

function* signOut() {
  try {
    yield axios.get('/logout');
    logOut();
  } catch (err) {
    console.log(err);
  }
}

export function setToken(tok) {
  token = tok;
}

export function getToken() {
  return token;
}

export function isAuth() {
  return !!token;
}

export function isLoggedIn() {
  return user;
}

export function logOut() {
  setToken(null);
  localStorage.clear();
  window.location.reload();
}

export default function* authSagaWatcher() {
  yield takeLatest(AUTH_LOGIN_REQUEST, authLogin)
  yield takeLatest(AUTH_REGISTER_REQUEST, authRegister)
  yield takeLatest(AUTH_CONFIRM_REQUEST, authConfirm)
  yield takeLatest(CURRENT_USER_REQUEST, currentUser)
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword)
  yield takeLatest(CONFIRM_RESET_PASSWORD_REQUEST, confirmResetPassword)
  yield takeLatest(UPDATE_NAME_REQUEST, updateFullName)
  yield takeLatest(UPDATE_EMAIL_REQUEST, updateEmail)
  yield takeLatest(CONFIRM_UPDATE_EMAIL_REQUEST, confirmUpdateEmail)
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword)
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount)
  yield takeLatest(CONFIRM_DELETE_ACCOUNT_REQUEST, confirmDeleteAccount)
  yield takeLatest(GET_SUBSCRIPTION_PLAN_REQUEST, fetchSubscriptionPlan)
  yield takeLatest(GET_SUBSCRIPTION_PLAN_NAME_REQUEST, getSubscriptionPlanName)
  yield takeLatest(AUTH_LOGOUT_REQUEST, signOut)
  yield takeLatest(DEVICE_LOGOUT_REQUEST, signOutDevice)
  yield takeLatest(ALL_DEVICES_LOGOUT_REQUEST, signOutAllDevices)
}
