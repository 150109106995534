import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateEmailRequest } from "../../redux/Auth/actions";
import usePrevious from "../../hooks/usePrevious";

import LoaderButton from "../../components/LoaderButton/Index";
import AuthErrorMessage from "../../components/AuthErrorMessage/Index";

import "./Email.scss"

function Email({isChangeEmailModalOpen, updateEmailData, onClose}) {

  const {
    isUpdateEmailSuccess,
    isUpdateEmailError,
    updateEmailErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
  });

  const dispatch = useDispatch();
  const prevIsUpdateEmailSuccess = usePrevious(isUpdateEmailSuccess);
  const prevIsUpdateEmailError = usePrevious(isUpdateEmailError);

  const [data, setData] = useState();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    if (isUpdateEmailSuccess && prevIsUpdateEmailSuccess === false) {
      updateEmailData(data)
      closeModal();
      setIsLoader(false);
      setEmailErrorMessage('');
    }
  }, [isUpdateEmailSuccess])

  useEffect(() => {
    if (isUpdateEmailError && prevIsUpdateEmailError === false) {
      setIsLoader(false);
      setEmailErrorMessage(updateEmailErrorMessage);
    }
  }, [isUpdateEmailError])

  function closeModal() {
    onClose(false)
  }

  function handleTogglePassword() {
    setIsPasswordShown(!isPasswordShown);
  }

  function updateSubmit(data) {
    dispatch(updateEmailRequest(data))
    setData(data);
    setIsLoader(true);
  }

  return (
    <>
      <Modal
        className="update-email-modal"
        title={<h2 className="title">Update email</h2>}
        open={isChangeEmailModalOpen}
        onCancel={closeModal}
        footer={null}
        centered
      >
        <form onSubmit={handleSubmit(updateSubmit)}>
          <div className="inputs">
            <input
              {...register('new_email', {
                required: 'Email is required',
                pattern: {
                  value: /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email address'
                },
              })}
              type="text"
              placeholder="New email"
              autoComplete="off"
              className={errors?.new_email ? 'input-error' : ''}
            />
            { errors?.new_email && <div className="error-message">{errors?.new_email?.message || 'Field is required'}</div> }
          </div>
          <div className="inputs">
            <div className="password-input">
              <input
                {...register('password',{
                  required: 'Password is required',
                  pattern: {
                    value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{8,}$/,
                    message: 'Password should include at least one letter, one numeric value and one special character'
                  },
                  minLength: {
                    value: 8,
                    message: 'Minimum required length is 8'
                  },
                  maxLength: {
                    value: 64,
                    message: 'Maximum required length is 64'
                  }
                })}
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Password"
                autoComplete="off"
                className={errors?.password ? 'input-error' : ''}
              />
              <div className="eye-icon" onClick={handleTogglePassword}>
                {isPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
              </div>
            </div>
            { errors?.password && <div className="error-message">{errors?.password?.message || 'Field is required'}</div> }
          </div>
          { emailErrorMessage &&
              <AuthErrorMessage errorMessage={emailErrorMessage} />
          }
          <div className="button">
            {
              isLoader ?
                <LoaderButton buttonText="Update" /> :
                <input type="submit" value="Update" />
            }
          </div>
        </form>
      </Modal>
    </>
  )
}

export default Email;
