import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNameRequest } from "../../redux/Auth/actions";
import { useForm } from "react-hook-form";
import usePrevious from "../../hooks/usePrevious";
import { Modal } from 'antd';

import LoaderButton from "../../components/LoaderButton/Index";
import AuthErrorMessage from "../../components/AuthErrorMessage/Index";

import "./FullName.scss"

function FullName({isChangeNameModalOpen, fullName = '', updateFullName, onClose}) {

  const {
    isUpdateNameSuccess,
    isUpdateNameError,
    updateNameErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      full_name: fullName,
    }
  });

  const dispatch = useDispatch();
  const prevIsUpdateNameSuccess = usePrevious(isUpdateNameSuccess);
  const prevIsUpdateNameError = usePrevious(isUpdateNameError);

  const [name, setName] = useState(fullName);
  const [isLoader, setIsLoader] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  useEffect(() => {
    if (isUpdateNameSuccess && prevIsUpdateNameSuccess === false) {
      updateFullName(name);
      closeModal();
      setIsLoader(false);
      setNameErrorMessage('');
    }
  }, [isUpdateNameSuccess])

  useEffect(() => {
    if (isUpdateNameError && prevIsUpdateNameError === false) {
      setIsLoader(false);
      setNameErrorMessage(updateNameErrorMessage);
    }
  }, [isUpdateNameError])

  function closeModal() {
    onClose(false);
  }

  function updateSubmit(data) {
    dispatch(updateNameRequest(data));
    setName(data.full_name);
    setIsLoader(true);
  }

  return (
    <>
      <Modal
        className="update-name-modal"
        title={<h2 className="title">Update name</h2>}
        open={isChangeNameModalOpen}
        onCancel={closeModal}
        footer={null}
        centered
      >
        <form onSubmit={handleSubmit(updateSubmit)}>
          <div className="inputs">
            <input
              {...register('full_name', {
                required: 'Full name is required'
              })}
              type="text"
              placeholder="Full name"
              className={errors?.full_name ? 'input-error' : ''}
              autoComplete="off"
            />
            { errors?.full_name && <div className="error-message">{errors?.full_name?.message || 'Field is required'}</div> }
          </div>
          { nameErrorMessage &&
              <AuthErrorMessage errorMessage={nameErrorMessage} />
          }
          <div className="button">
            {
              isLoader ?
              <LoaderButton buttonText="Update" /> :
              <input type="submit" value="Update" />
            }
          </div>
        </form>
      </Modal>
    </>
  )
}

export default FullName;
