import React from "react";
import Logo from "../../assets/images/logo.png"
import { NavLink, useNavigate } from "react-router-dom";

import "./Index.scss"

function Header() {

  const navigate = useNavigate();

  function toLogin() {
    navigate("/login");
  }

  return (
    <div className="header">
      <NavLink to="">
        <div className="logo">
          <img src={Logo} alt="Talk2Edit"/>
        </div>
      </NavLink>
      <div className="items">
        <NavLink to="" className="nav-link" end>
          <p>Product</p>
        </NavLink>
        <NavLink to="pricing" className="nav-link">
          <p>Pricing</p>
        </NavLink>
        <NavLink to="about-us" className="nav-link">
          <p>About Us</p>
        </NavLink>
        <NavLink to="contact-us" className="nav-link">
          <p>Contact us</p>
        </NavLink>
      </div>
      <div className="login G-cursor-pointer" onClick={toLogin}>
        <p>Log in / Sign up</p>
      </div>
    </div>
  )
}

export default Header;
