import {
  AUTH_CONFIRM_ERROR,
  AUTH_CONFIRM_REQUEST,
  AUTH_CONFIRM_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  CONFIRM_RESET_PASSWORD_REQUEST,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CURRENT_USER_ERROR,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UPDATE_NAME_ERROR,
  UPDATE_NAME_REQUEST,
  UPDATE_NAME_SUCCESS,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  CONFIRM_UPDATE_EMAIL_REQUEST,
  CONFIRM_UPDATE_EMAIL_SUCCESS,
  CONFIRM_UPDATE_EMAIL_ERROR,
  AUTH_LOGOUT_REQUEST,
  DEVICE_LOGOUT_REQUEST,
  ALL_DEVICES_LOGOUT_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  CONFIRM_DELETE_ACCOUNT_REQUEST,
  CONFIRM_DELETE_ACCOUNT_SUCCESS,
  CONFIRM_DELETE_ACCOUNT_ERROR,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_ERROR,
  GET_SUBSCRIPTION_PLAN_NAME_REQUEST,
  GET_SUBSCRIPTION_PLAN_NAME_SUCCESS,
  RESET_SUBSCRIPTION_PLAN,
} from "./types";

export const authLoginRequest = (payload) => ({
  type: AUTH_LOGIN_REQUEST,
  payload,
})

export const authLoginSuccess = (payload) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload,
})

export const authLoginError = (payload) => ({
  type: AUTH_LOGIN_ERROR,
  payload,
})


export const authRegisterRequest = (payload) => ({
  type: AUTH_REGISTER_REQUEST,
  payload,
})

export const authRegisterSuccess = (payload) => ({
  type: AUTH_REGISTER_SUCCESS,
  payload,
})

export const authRegisterError = (payload) => ({
  type: AUTH_REGISTER_ERROR,
  payload,
})


export const authConfirmRequest = (payload) => ({
  type: AUTH_CONFIRM_REQUEST,
  payload,
})

export const authConfirmSuccess = () => ({
  type: AUTH_CONFIRM_SUCCESS,
})

export const authConfirmError = (payload) => ({
  type: AUTH_CONFIRM_ERROR,
  payload,
})


export const currentUserRequest = () => ({
  type: CURRENT_USER_REQUEST,
})

export const currentUserSuccess = (payload) => ({
  type: CURRENT_USER_SUCCESS,
  payload,
})

export const currentUserError = (payload) => ({
  type: CURRENT_USER_ERROR,
  payload,
})


export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordError = (payload) => ({
  type: RESET_PASSWORD_ERROR,
  payload,
})


export const confirmResetPasswordRequest = (payload) => ({
  type: CONFIRM_RESET_PASSWORD_REQUEST,
  payload,
})

export const confirmResetPasswordSuccess = () => ({
  type: CONFIRM_RESET_PASSWORD_SUCCESS,
})

export const confirmResetPasswordError = (payload) => ({
  type: CONFIRM_RESET_PASSWORD_ERROR,
  payload,
})


export const updateNameRequest = (payload) => ({
  type: UPDATE_NAME_REQUEST,
  payload,
})

export const updateNameSuccess = () => ({
  type: UPDATE_NAME_SUCCESS,
})

export const updateNameError = (payload) => ({
  type: UPDATE_NAME_ERROR,
  payload,
})


export const updateEmailRequest = (payload) => ({
  type: UPDATE_EMAIL_REQUEST,
  payload
})

export const updateEmailSuccess = () => ({
  type: UPDATE_EMAIL_SUCCESS,
})

export const updateEmailError = (payload) => ({
  type: UPDATE_EMAIL_ERROR,
  payload,
})


export const confirmUpdateEmailRequest = (payload) => ({
  type: CONFIRM_UPDATE_EMAIL_REQUEST,
  payload,
})

export const confirmUpdateEmailSuccess = () => ({
  type: CONFIRM_UPDATE_EMAIL_SUCCESS,
})

export const confirmUpdateEmailError = (payload) => ({
  type: CONFIRM_UPDATE_EMAIL_ERROR,
  payload,
})


export const updatePasswordRequest = (payload) => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload,
})

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
})

export const updatePasswordError = (payload) => ({
  type: UPDATE_PASSWORD_ERROR,
  payload,
})


export const deleteAccountRequest = () => ({
  type: DELETE_ACCOUNT_REQUEST,
})

export const deleteAccountSuccess = () => ({
  type: DELETE_ACCOUNT_SUCCESS,
})

export const deleteAccountError = (payload) => ({
  type: DELETE_ACCOUNT_ERROR,
  payload,
})


export const confirmDeleteAccountRequest = (payload) => ({
  type: CONFIRM_DELETE_ACCOUNT_REQUEST,
  payload,
})

export const confirmDeleteAccountSuccess = (payload) => ({
  type: CONFIRM_DELETE_ACCOUNT_SUCCESS,
  payload,
})

export const confirmDeleteAccountError = (payload) => ({
  type: CONFIRM_DELETE_ACCOUNT_ERROR,
  payload,
})


export const fetchSubscriptionPlanRequest = () => ({
  type: GET_SUBSCRIPTION_PLAN_REQUEST,
})

export const fetchSubscriptionPlanSuccess = (payload) => ({
  type: GET_SUBSCRIPTION_PLAN_SUCCESS,
  payload,
})

export const fetchSubscriptionPlanError = (payload) => ({
  type: GET_SUBSCRIPTION_PLAN_ERROR,
  payload,
})

export const getSubscriptionPlanNameRequest = () => ({
  type: GET_SUBSCRIPTION_PLAN_NAME_REQUEST,
})

export const getSubscriptionPlanNameSuccess = (payload) => ({
  type: GET_SUBSCRIPTION_PLAN_NAME_SUCCESS,
  payload,
})

export const resetSubscriptionPlan = () => ({
  type: RESET_SUBSCRIPTION_PLAN,
})


export const deviceLogoutRequest = (payload) => ({
  type: DEVICE_LOGOUT_REQUEST,
  payload,
})

export const allDevicesLogoutRequest = () => ({
  type: ALL_DEVICES_LOGOUT_REQUEST,
})

export const authLogoutRequest = () => ({
  type: AUTH_LOGOUT_REQUEST,
})