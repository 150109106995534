import React from "react";
import resolution from "../../assets/images/resolution.png";

import "./Index.scss"

function SmallScreenResolution() {

  return (
    <div className="mobile-container">
      <div className="mobile-container-content">
        <img src={resolution} alt=""/>
        <p>Sorry</p>
        <h3>To fully enjoy our application's features, please use a desktop, laptop or tablet. On smaller screens, some features may not be visible or fully accessible.</h3>
      </div>
    </div>
  )
}

export default SmallScreenResolution;
