import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDocumentRequest,
  downloadDocDocumentRequest,
  downloadPdfDocumentRequest
} from "../../redux/Document/actions";
import { NavLink, useNavigate } from "react-router-dom";

import RemoveDocument from "../../modals/DocumentDelete/RemoveDocument";
import usePrevious from "../../hooks/usePrevious";

import { MdDelete } from "react-icons/md";
import { BsFiletypeDocx } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa6";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import classNames from "classnames";

import "./Index.scss"

function Document({ document_id, document_title, onRemoveId }) {

  const {
    isDownloadPdfDocumentSuccess, isDownloadPdfDocumentError, downloadPdfDocumentErrorMessage, downloadPdfDocument,
    isDownloadDocDocumentSuccess, isDownloadDocDocumentError, downloadDocDocumentErrorMessage, downloadDocDocument,
  } = useSelector(state => state.document)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevIsDownloadPdfDocumentSuccess = usePrevious(isDownloadPdfDocumentSuccess);
  const prevIsDownloadDocDocumentSuccess = usePrevious(isDownloadDocDocumentSuccess);

  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false)
  const [isDownloadSectionOpen, setIsDownloadSectionOpen] = useState(false)
  const [isLoader, setIsLoader] = useState(false);
  const [downloadType, setDownloadType] = useState(null);

  useEffect(() => {
    if (downloadType && ((isDownloadPdfDocumentSuccess && prevIsDownloadPdfDocumentSuccess === false) || (isDownloadDocDocumentSuccess && prevIsDownloadDocDocumentSuccess === false))) {
      downloadDocument(
          downloadType === 'pdf' ? downloadPdfDocument : downloadDocDocument,
          downloadType === 'pdf' ? "application/pdf" : "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      setDownloadType(null); // Reset after download
    }
  }, [isDownloadPdfDocumentSuccess, isDownloadDocDocumentSuccess]);

  function toggleDeleteDocumentModal(isOpen) {
    setIsDeleteDocumentModalOpen(isOpen);
  }

  function toggleDownloadSectionOpen(isOpen) {
    setIsDownloadSectionOpen(isOpen);
  }

  function toDocumentDetails() {
    navigate(`/document-details/${document_id}`);
  }

  function removeDocument() {
    dispatch(deleteDocumentRequest(document_id))
    onRemoveId(document_id)
  }

  function convertTo(type, e) {
    if (e) {
      e.stopPropagation();
    }
    setIsLoader(true);
    dispatch(type === 'pdf' ? downloadPdfDocumentRequest(document_id) : downloadDocDocumentRequest(document_id));
    setDownloadType(type);
  }

  function downloadDocument(downloadLink, downloadType) {
    toggleDownloadSectionOpen(false);
    setIsLoader(false);
    const documentBlob = new Blob([downloadLink], { type: downloadType });
    const url = window.URL.createObjectURL(documentBlob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = document_title;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url)
  }

  return (
    <div className={classNames('document', {'disabled': isLoader})}>
      {/*<NavLink to={`/document-details/${document_id}`}>*/}
      {/*  {document_title}*/}
      {/*</NavLink>*/}
      <p className="document-title" onClick={toDocumentDetails}>{document_title}</p>
      <div className="download-content" onClick={toDocumentDetails}>
        <div className="download-icons-container">
          <FaRegFilePdf className="download-icons pdf-icon" onClick={(e) => convertTo('pdf', e)} />
          <BsFiletypeDocx className="download-icons doc-icon" onClick={(e) => convertTo('doc', e)} />
        </div>
        {isLoader &&
          <div className="loader">
            <Spin size="large" indicator={<LoadingOutlined spin />} />
          </div>
        }
      </div>
      <div className="bottom-section">
        <MdDelete title="Delete" className="icons" onClick={() => toggleDeleteDocumentModal(true)} />
      </div>
      {isDeleteDocumentModalOpen ? <RemoveDocument isDeleteDocumentModalOpen={isDeleteDocumentModalOpen} document_title={document_title} onRemove={removeDocument} onClose={toggleDeleteDocumentModal} /> : null}
    </div>
  )
}

export default Document;
