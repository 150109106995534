import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons';
import { SendOutlined } from '@ant-design/icons';

import classNames from "classnames";

import "./Index.scss"

const formats = ['audio/mpeg', 'audio/wav', 'video/mp4'];

function AudioUpload({isUploadDisabled, isFileReset, onRecordingDisable, onUpload, onResetFile, onDurationShown, onFormatShown, onSizeShown}) {
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (isFileReset) {
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [isFileReset])

  function handleFileChange({target}) {
    const audioData = target.files[0];
    const audioBlob = audioData ? new Blob([audioData], { type: audioData.type }) : null;
    if (audioData) {
      const audio = new Audio(URL.createObjectURL(audioData));
      const handleDuration = () => {
        const sizeInMB = (audioData.size / (1024 * 1024)).toFixed(2);
        const duration = Math.floor(audio.duration);
        if (duration >= 3600) {
          onDurationShown(true);
          setSelectedFile(null)
        } else if (!formats.includes(audioData.type)) {
          onFormatShown(true);
        } else if (sizeInMB >= 180) {
          onSizeShown(true)
        } else {
          setSelectedFile(audioBlob);
          onResetFile(false);
          onDurationShown(false);
          onFormatShown(false);
          onSizeShown(false);
        }
      }
      audio.addEventListener('loadedmetadata', handleDuration)
    } else {
      setSelectedFile(null);
    }
  }

  function fileUpload() {
    onUpload(selectedFile);
    onRecordingDisable(true);
  }

  return (
    <div className="audio-upload">
      <div>
        <Button
          type="primary"
          icon={<CloudUploadOutlined className="button-icons" />}
          size="large"
          className={classNames('file-buttons', {'disable': isUploadDisabled})}
          onClick={() => fileInputRef.current.click()}
        >
          Upload file
          <input
            id="file-upload"
            type="file"
            accept="audio/wav,audio/mp3,video/mp4"
            onChange={handleFileChange}
            ref={fileInputRef}
            disabled={isUploadDisabled}
            hidden
          />
        </Button>
      </div>
      {selectedFile ?
        <div className="send-button">
          <Button
            type="primary"
            icon={<SendOutlined className="button-icons" />}
            size="large"
            className={classNames('file-buttons', {'disable': isUploadDisabled})}
            onClick={fileUpload}
          >
            Send
          </Button>
        </div> : null
      }
    </div>
  );
}

export default AudioUpload;
