import React, { useEffect } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { logOut, setToken } from "../../redux/Auth/saga";
import { useSelector } from "react-redux";

function DeletedAccountMessage({isDeletedAccountMessageModalOpen}) {

  const {
    isConfirmDeleteAccountSuccess,
    confirmDeleteAccount,
  } = useSelector(state => state.auth)

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setToken(null)
      localStorage.clear();
      navigate("/");
      window.location.reload();
      // logOut();
    }, 5000)
  }, [])

  return (
    <>
      <Modal
        className="confirm-email-update-modal"
        title={isConfirmDeleteAccountSuccess && <h2 className="title G-text-center">{confirmDeleteAccount}</h2>}
        open={isDeletedAccountMessageModalOpen}
        footer={null}
        closable={false}
        keyboard={false}
        centered
      >
      </Modal>
    </>
  )
}

export default DeletedAccountMessage;
