import { put, takeLatest } from "redux-saga/effects"
import { RECORD_TRANSCRIBE_EN_REQUEST, RECORD_TRANSCRIBE_HY_REQUEST, RECORD_TRANSCRIBE_RU_REQUEST } from "./types";
import axios from "axios";
import {
  recordTranscribeError,
  recordTranscribeSuccess
} from "./actions";

function* recordTranscribeHy({payload}) {
  try {
    const response = yield axios.post('/transcribe_hy', payload)
    // const response = yield axios.get('https://dummyjson.com/products/1')
    yield put(recordTranscribeSuccess(response.data))
  } catch (err) {
    yield put(recordTranscribeError(err.response.data))
  }
}

function* recordTranscribeEn({payload}) {
  try {
    const response = yield axios.post('/transcribe_en', payload)
    yield put(recordTranscribeSuccess(response.data))
  } catch (err) {
    yield put(recordTranscribeError(err.response.data))
  }
}

function* recordTranscribeRu({payload}) {
  try {
    const response = yield axios.post('/transcribe_ru', payload)
    yield put(recordTranscribeSuccess(response.data))
  } catch (err) {
    yield put(recordTranscribeError(err.response.data))
  }
}

export default function* recordSagaWatcher() {
  yield takeLatest(RECORD_TRANSCRIBE_HY_REQUEST, recordTranscribeHy)
  yield takeLatest(RECORD_TRANSCRIBE_EN_REQUEST, recordTranscribeEn)
  yield takeLatest(RECORD_TRANSCRIBE_RU_REQUEST, recordTranscribeRu)
}
