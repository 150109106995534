import {
  RECORD_TRANSCRIBE_EN_REQUEST,
  RECORD_TRANSCRIBE_HY_REQUEST,
  RECORD_TRANSCRIBE_RU_REQUEST,
  RECORD_TRANSCRIBE_SUCCESS,
  RECORD_TRANSCRIBE_ERROR,
} from "./types";

const initialState = {
  isRecordTranscribeSuccess: false,
  isRecordTranscribeError: false,
  recordTranscribeErrorMessage: '',
  recordTranscribe: {},
}

export const recordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECORD_TRANSCRIBE_HY_REQUEST:
      return {
        ...state,
        isRecordTranscribeSuccess: false,
        isRecordTranscribeError: false,
        recordTranscribeErrorMessage: '',
        recordTranscribe: {},
      }
    case RECORD_TRANSCRIBE_EN_REQUEST:
      return {
        ...state,
        isRecordTranscribeSuccess: false,
        isRecordTranscribeError: false,
        recordTranscribeErrorMessage: '',
        recordTranscribe: {},
      }
    case RECORD_TRANSCRIBE_RU_REQUEST:
      return {
        ...state,
        isRecordTranscribeSuccess: false,
        isRecordTranscribeError: false,
        recordTranscribeErrorMessage: '',
        recordTranscribe: {},
      }
    case RECORD_TRANSCRIBE_SUCCESS:
      return {
        ...state,
        isRecordTranscribeSuccess: true,
        isRecordTranscribeError: false,
        recordTranscribeErrorMessage: '',
        recordTranscribe: action.payload,
      }
    case RECORD_TRANSCRIBE_ERROR:
      return {
        ...state,
        isRecordTranscribeSuccess: false,
        isRecordTranscribeError: true,
        recordTranscribeErrorMessage: action.payload,
        recordTranscribe: {},
      }
    default:
      return state
  }
}
