import React from "react";

import "./Index.scss"

function PrivacyPolicy() {

  return (
    <div className="privacy-policy">
      <div className="privacy-policy-content">
        <div className="privacy-policy-content-section">
          <h1>Privacy Policy</h1>
          <h2>Introduction</h2>
          <p>We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.
            This Privacy Policy outlines how we collect, use, and protect your information when you use our web application.</p>
        </div>
        <div className="privacy-policy-content-section">
          <h2>Data Collection and Usage</h2>
          <div className="privacy-policy-content-sub-section">
            <span className="privacy-policy-section-bold-text">Voice Data</span>
            <ul>
              <li><span className="privacy-policy-section-bold-text">Temporary Storage</span>: We do not keep voice data (data to be transcribed) permanently. Only temporary speech signals generated from submitted audio,
                video, or microphone streaming data are stored on our servers. These signals are automatically deleted after the transcription process is completed.</li>
            </ul>
          </div>
          <div className="privacy-policy-content-sub-section">
            <span className="privacy-policy-section-bold-text">User Documents</span>
            <ul>
              <li><span className="privacy-policy-section-bold-text">Encryption</span>: Your documents are encrypted, and our web app admins do not have access to them.
                This ensures that your data remains private and secure.</li>
            </ul>
          </div>
          <div className="privacy-policy-content-sub-section">
            <span className="privacy-policy-section-bold-text">Personal Information</span>
            <ul>
              <li><span className="privacy-policy-section-bold-text">Registration Data</span>: We collect and retain personal information such as your name and email address during registration.
                Some sensitive data is encrypted to provide an additional layer of security.</li>
              <li><span className="privacy-policy-section-bold-text">Data Security</span>: We are committed to ensuring the security of your personal information. Our servers are protected by
                appropriate security measures to prevent unauthorized access.</li>
            </ul>
          </div>
        </div>
        <div className="privacy-policy-content-section">
          <h2>Data Sharing</h2>
          <ul>
            <li><span className="privacy-policy-section-bold-text">No Third-Party Sharing</span>: We do not share any user data or content with third-party applications.
              Your information remains confidential and is used solely for the purpose of providing and improving our services.</li>
          </ul>
        </div>
        <div className="privacy-policy-content-section">
          <h2>Policy Changes</h2>
          <ul>
            <li><span className="privacy-policy-section-bold-text">Notification of Changes</span>: In the event of any changes to our Privacy Policy, we will inform you via email or phone call.
              It is important to us that you are always aware of how your information is being used and protected.</li>
          </ul>
        </div>
        <div className="privacy-policy-content-section">
          <h2>Age Restrictions</h2>
          <ul>
            <li><span className="privacy-policy-section-bold-text">No Age Restrictions</span>: There are no age restrictions for using our web application. We aim to make our services accessible to all users.</li>
          </ul>
        </div>
        <div className="privacy-policy-content-section">
          <h2>Contact Us</h2>
          <p>If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
          <ul>
            <li><span className="privacy-policy-section-bold-text">Email</span>: talk2edit.help@gmail.com</li>
            <li><span className="privacy-policy-section-bold-text">Facebook</span>: <a href="https://www.facebook.com/profile.php?id=61562177254598" target="_blank">Facebook page</a></li>
            <li><span className="privacy-policy-section-bold-text">Instagram</span>: <a href="https://www.instagram.com/talk2edit/" target="_blank">Instagram page</a></li>
          </ul>
        </div>
        <div className="privacy-policy-content-section">
          <p>Thank you for trusting us with your personal information. We are dedicated to protecting your privacy and providing a secure and reliable service.</p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
