import { combineReducers } from "redux";
import { authReducer } from "./Auth/reducer";
import { recordReducer } from "./Record/reducer";
import { documentReducer } from "./Document/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  record: recordReducer,
  document: documentReducer,
})
