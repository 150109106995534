import React from "react";
import SubscriptionCard from "../../components/SubscriptionCard/Index";
import { isAuth } from "../../redux/Auth/saga";
import { Navigate } from "react-router-dom";

import "./Index.scss"

const subscriptionPlans = [
  {
    id: 0,
    type: 'Free',
    price: '0',
    options: [
      'Enjoy 1 free hour of transcription time per month.',
      'Transcribe up to 5 audio and video files (MP3, WAV, MP4) per month.',
      'Create up to 5 documents.',
      'Download documents and transcriptions in DOCX, PDF, and TXT formats.',
      'Transcription available in 2 languages (Armenian and English) with punctuation and capitalization features.',
      'Maximum 1 hour duration per conversation and file upload.',
    ],
  },
  {
    id: 1,
    type: 'Standard',
    price: '3500',
    options: [
      'Enjoy 5 hours of transcription time per month.',
      'Transcribe unlimited audio and video files (MP3, WAV, MP4) per month.',
      'Create up to 50 documents.',
      'Download documents and transcriptions in DOCX, PDF, and TXT formats.',
      'Transcription available in 2 languages (Armenian and English) with punctuation and capitalization features.',
      'Maximum 1 hour duration per conversation and file upload.',
    ],
  },
  {
    id: 2,
    type: 'Premium',
    price: '6000',
    previousPrice: '7000',
    options: [
      'Enjoy 50 hours of transcription time per month.',
      'Transcribe unlimited audio and video files (MP3, WAV, MP4) per month.',
      'Create up to 100 documents.',
      'Download documents and transcriptions in DOCX, PDF, and TXT formats.',
      'Transcription available in 2 languages (Armenian and English) with punctuation and capitalization features.',
      'Maximum 1 hour duration per conversation and file upload.',
    ],
    isRecommendedLabelShown: false,
  }
];

function Pricing() {

  return (
    <div className="pricing">
      <div className="pricing-title">
        <h1>Effortless Transcriptions Start with a Plan</h1>
      </div>
      <div className="pricing-container">
        {subscriptionPlans.map((subscription) => (
          <div className="subscription-plans" key={subscription.id}>
            <SubscriptionCard {...subscription} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Pricing;
