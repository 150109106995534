import React, { useState } from "react";
import { FaMicrophone, FaRegStopCircle } from "react-icons/fa";
import RecordRTC from "recordrtc";
import RecordingAnimation from "../RecordingAnimation/Index";
import Stopwatch from "../StopWatch/Index";
import classNames from "classnames";
import { Progress, Spin } from 'antd';

import "./Index.scss"

function AudioRecorder ({ selectedLanguage, selectedFilters, progress, isLoading, toggleLoader, sendAudioBlob })  {

  const [microphone, setMicrophone] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isTimerShown, setIsTimerShown] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);

  const captureMicrophone = async () => {
    if (!microphone) {
      try {
        const constraints = {
          video: false,  // Disable video
          audio: {
            autoGainControl: false,
            echoCancellation: true,
            noiseSuppression: true,
          }
        };
        const mic = await navigator.mediaDevices.getUserMedia(constraints);
        setMicrophone(mic);
        return mic;
      } catch (error) {
        console.error('Error capturing microphone:', error);
        alert('Unable to capture your microphone. Please check console logs.');
      }
    }
    return microphone;
  };

  const startRecording = async () => {
    if (!isRecording) {
      const mic = await captureMicrophone();
      if (mic) {
        const options = {
          type: 'audio',
          mimeType: 'audio/wav',
          numberOfAudioChannels: 1,
          checkForInactiveTracks: true,
          bufferSize: 16384,
          sampleRate: 16000,
          desiredSampRate: 16000,
          bitrate: 256000,
          disableLogs: true,
        };
        const newRecorder = new RecordRTC(mic, options);
        newRecorder.startRecording();
        setRecorder(newRecorder);
        setIsRecording(true);
        toggleTimer(true);
      }
    } else {
      stopRecording();
    }
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const audioBlob = recorder.getBlob();
        handleRecordingStopped(audioBlob);
        sendAudioBlob(audioBlob)
        releaseMicrophone();
        toggleTimer(false);
        toggleLoader(true);
        setIsRecording(false);
      });
    }
  };

  const releaseMicrophone = () => {
    if (microphone) {
      microphone.getTracks().forEach(track => track.stop());
      setMicrophone(null);
    }
  };

  const handleRecordingStopped = (audioBlob) => {
    const { id } = selectedLanguage;
    const formData = new FormData();
    formData.append('speech_audio', audioBlob, 'recording.wav');
    Object.keys(selectedFilters).forEach((key) => {
      formData.append(key, selectedFilters[key]);
    });
  };

  function toggleTimer(isTimer) {
    setIsTimerShown(isTimer);
    setIsTimerStarted(isTimer);
  }

  function onStopDueDuration(isStopped) {
    if (isStopped) {
      stopRecording();
    }
  }

  return (
    <div className="audio-recorder">
      <RecordingAnimation isRecording={isRecording} />
      {isLoading ?
        <div className="audio-recorder-loader-container">
          <Progress type="circle" trailColor="#FFF" percent={progress} />
        </div> :
        <div className="record-container">
          <div className={classNames('record', {'recording': isRecording})}>
            <div className={classNames({'initial-icon': !isRecording, 'mic-icon': isRecording})}  onClick={startRecording}>
              <div className="record-icons-container">
                {isRecording ?
                  <FaRegStopCircle className="audio-icons stop-icon"/> :
                  <FaMicrophone className="audio-icons recording-icon"/>
                }
              </div>
            </div>
            {isTimerShown && <Stopwatch isStarted={isTimerStarted} onRecordingStop={onStopDueDuration} isSmall={true} />}
          </div>
        </div>
      }
    </div>
  )
}

export default AudioRecorder;
