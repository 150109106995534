import React from "react";
import { Outlet, useLocation } from "react-router-dom"
import Header from "../Header/Index";
import Footer from "../Footer/Index";

import "./Main.scss"

function Main() {
  const location = useLocation();
  const isHeaderAndFooterShown = location.pathname !== '/login';

  return (
    <div className="main">
      {isHeaderAndFooterShown ?
        <>
          <Header />
          <div className="main-page">
            <Outlet />
          </div>
          <Footer />
        </> :
        <Outlet />
      }
    </div>
  )
}
export default Main
