import React from "react";
import { useNavigate } from "react-router-dom";

import Facebook from "../../assets/images/facebook.png"
import Instagram from "../../assets/images/instagram.png"

import "./Index.scss"

const date = new Date();
const year = date.getFullYear();

function Footer() {

  const navigate = useNavigate()

  function toPrivacyPolicy() {
    navigate("privacy-policy");
  }

  function toTermsConditions() {
    navigate("terms-conditions");
  }

  return (
    <div className="footer">
      <div className="column-container">
        <p onClick={toPrivacyPolicy}>Privacy Policy</p>
        <p onClick={toTermsConditions}>Terms And Conditions</p>
      </div>
      <div className="column-container">
        <a href="https://www.facebook.com/profile.php?id=61562177254598" target="_blank"><img src={Facebook} alt="facebook"/></a>
        <a href="https://www.instagram.com/talk2edit/" target="_blank"><img src={Instagram} alt="instagram"/></a>
      </div>
      <div className="column-container">
        <span>{year} © Talk2Edit</span>
      </div>
    </div>
  )
}

export default Footer;
