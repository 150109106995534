import axios from "axios";
import { put, takeLatest } from "redux-saga/effects"
import {
  CREATE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  DOWNLOAD_DOC_DOCUMENT_REQUEST,
  DOWNLOAD_PDF_DOCUMENT_REQUEST,
  DOWNLOAD_TXT_REQUEST,
  GET_CURRENT_DOCUMENT_REQUEST,
  GET_DOCUMENTS_LIST_REQUEST,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_TITLE_REQUEST
} from "./types";
import {
  createDocumentError,
  createDocumentSuccess,
  currentDocumentError,
  currentDocumentSuccess,
  deleteDocumentError,
  deleteDocumentSuccess,
  downloadDocDocumentSuccess,
  downloadPdfDocumentError,
  downloadPdfDocumentSuccess, downloadTxtError, downloadTxtSuccess,
  fetchDocumentsError,
  fetchDocumentsSuccess,
  updateDocumentError,
  updateDocumentSuccess, updateDocumentTitleError, updateDocumentTitleSuccess
} from "./actions";

function* fetchDocuments() {
  try {
    const response = yield axios.get('/get_documents_list')
    // const response = yield axios.get('https://dummyjson.com/products')
    yield put(fetchDocumentsSuccess(response.data))
  } catch (err) {
    yield put(fetchDocumentsError(err.response.data))
  }
}

function* createDocument() {
  try {
    const response = yield axios.get('/create_document')
    // const response = yield axios.get('https://dummyjson.com/products/7')
    yield put(createDocumentSuccess(response.data))
  } catch (err) {
    yield put(createDocumentError(err.response.data))
  }
}

function* deleteDocument({ payload }) {
  try {
    yield axios.delete(`/delete_document/${payload}`)
    // const response = yield axios.delete(`https://dummyjson.com/products/${payload}`)
    yield put(deleteDocumentSuccess())
  } catch (err) {
    yield put(deleteDocumentError(err.response.data))
  }
}

function* currentDocument({ payload }) {
  try {
    const response = yield axios.get(`/get_document/${payload}`)
    // const response = yield axios.get(`https://dummyjson.com/products/${payload}`)
    yield put(currentDocumentSuccess(response.data))
  } catch (err) {
    yield put(currentDocumentError(err.response.data))
  }
}

function* updateDocumentTitle({ payload: { id, body } }){
  try {
    yield axios.put(`/update_document_title/${id}`, body)
    yield put(updateDocumentTitleSuccess())
  } catch (err) {
    yield put(updateDocumentTitleError(err.response.data))
  }
}

function* updateDocument({ payload: { id, body } }) {
  try {
    yield axios.put(`/update_document/${id}`, body)
    yield put(updateDocumentSuccess())
  } catch (err) {
    yield put(updateDocumentError(err.response.data))
  }
}

function* downloadPdfDocument({ payload }) {
  try {
    const response = yield axios.get(`/download_document_pdf/${payload}`, { responseType: 'blob' })
    // const response = yield axios.get(`https://dummyjson.com/products/${payload}`)
    yield put(downloadPdfDocumentSuccess(response.data))
  } catch (err) {
    yield put(downloadPdfDocumentError(err.response.data))
  }
}

function* downloadDocDocument({ payload }) {
  try {
    const response = yield axios.get(`/download_document_docx/${payload}`, { responseType: 'blob' })
    // const response = yield axios.get(`https://dummyjson.com/products/${payload}`)
    yield put(downloadDocDocumentSuccess(response.data))
  } catch (err) {
    yield put(downloadPdfDocumentError(err.response.data))
  }
}

function* downloadTxt({ payload }) {
  try {
    const response = yield axios.post('/download_document_txt', payload, { responseType: 'blob' })
    yield put(downloadTxtSuccess(response.data))
  } catch (err) {
    yield put(downloadTxtError(err))
  }
}

export default function* documentSagaWatcher() {
  yield takeLatest(GET_DOCUMENTS_LIST_REQUEST, fetchDocuments)
  yield takeLatest(CREATE_DOCUMENT_REQUEST, createDocument)
  yield takeLatest(DELETE_DOCUMENT_REQUEST, deleteDocument)
  yield takeLatest(GET_CURRENT_DOCUMENT_REQUEST, currentDocument)
  yield takeLatest(UPDATE_DOCUMENT_TITLE_REQUEST, updateDocumentTitle)
  yield takeLatest(UPDATE_DOCUMENT_REQUEST, updateDocument)
  yield takeLatest(DOWNLOAD_PDF_DOCUMENT_REQUEST, downloadPdfDocument)
  yield takeLatest(DOWNLOAD_DOC_DOCUMENT_REQUEST, downloadDocDocument)
  yield takeLatest(DOWNLOAD_TXT_REQUEST, downloadTxt)
}
