import React from "react";

import "./Index.scss";

function Subtitle() {

  return (
  <div className="subtitle">
    <div className="subtitle-soon"></div>
  </div>
  )
}

export default Subtitle;