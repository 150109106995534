import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import usePrevious from "../../hooks/usePrevious";
import { confirmDeleteAccountRequest, deleteAccountRequest } from "../../redux/Auth/actions";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton/Index";
import AuthErrorMessage from "../../components/AuthErrorMessage/Index";

import "./ConfirmDeleteAccount.scss"

function ConfirmDeleteAccount({isConfirmDeleteAccountModalOpen, email, openDeletedMessageModal, onClose}) {
  const {
    isConfirmDeleteAccountSuccess,
    isConfirmDeleteAccountError,
    confirmDeleteAccountErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onTouched'
  });

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const prevIsConfirmDeleteAccountSuccess = usePrevious(isConfirmDeleteAccountSuccess);
  const prevIsConfirmDeleteAccountError = usePrevious(isConfirmDeleteAccountError);

  const [isLoader, setIsLoader] = useState(false);
  const [deleteAccountErrorMessage, setDeleteAccountErrorMessage] = useState('');

  useEffect(() => {
    if (isConfirmDeleteAccountSuccess && prevIsConfirmDeleteAccountSuccess === false) {
      openDeletedMessageModal(true)
      closeModal();
      setIsLoader(false);
      setDeleteAccountErrorMessage('');
    }
  }, [isConfirmDeleteAccountSuccess])

  useEffect(() => {
    if (isConfirmDeleteAccountError && prevIsConfirmDeleteAccountError === false) {
      setDeleteAccountErrorMessage(confirmDeleteAccountErrorMessage);
      setIsLoader(false);
    }
  }, [isConfirmDeleteAccountError])

  function closeModal() {
    onClose(false)
  }

  function handleResendCode() {
    dispatch(deleteAccountRequest())
    reset();
  }

  function updateSubmit(data) {
    dispatch(confirmDeleteAccountRequest(data))
    setIsLoader(true);
  }

  return (
    <>
      <Modal
        className="confirm-email-update-modal"
        title={<h2 className="title">Confirmation code</h2>}
        open={isConfirmDeleteAccountModalOpen}
        onCancel={closeModal}
        footer={null}
        centered
      >
        <form onSubmit={handleSubmit(updateSubmit)}>
        <div className="text">
          {`The confirmation code has been sent successfully to email ${email}. Please enter the 6-digit code to proceed.`}
        </div>
          <div className="inputs">
            <input
              {...register('confirmation_code', {
                required: true,
                minLength: {
                  value: 6,
                  message: 'The length should be exactly 6'
                },
                maxLength: {
                  value: 6,
                  message: 'The length should be exactly 6'
                },
              })}
              type="text"
              placeholder="Verification code"
              className={errors?.confirmation_code ? 'input-error' : ''}
              autoComplete="off"
            />
            { errors?.confirmation_code && <div className="error-message">{errors?.confirmation_code?.message || 'Field is required'}</div> }
          </div>
          { deleteAccountErrorMessage &&
              <AuthErrorMessage errorMessage={deleteAccountErrorMessage} />
          }
          <div className="button">
            {
              isLoader ?
                <LoaderButton buttonText="Verify code" /> :
                <input type="submit" value="Verify code" />
            }
          </div>
        </form>
        <div className="resend">
          <p onClick={handleResendCode}>Resend the code</p>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmDeleteAccount;
