import React from "react";
import { isAuth } from "../../redux/Auth/saga";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import classNames from "classnames";

import "./Index.scss"

function SubscriptionCard({ id, type, price, previousPrice = '', options, isRecommendedLabelShown = false, selectedSubscription = 'Free', toggleUpgradePlanModalOpen }) {

  const navigate = useNavigate()

  function toLogin() {
    navigate("/login")
  }

  return (
    <>
      {isAuth() ?
        <div className={classNames('subscription-card', {'selected': type === selectedSubscription})}>
          {
            type === selectedSubscription &&
            <div className="current-label">
              Current
            </div>
          }
          <div className="subscription-type">
            <h2>{type} {price}֏ {previousPrice && <span className="strikethrough-diagonal">{previousPrice}֏</span>}</h2>
            {isRecommendedLabelShown && <div className="recommended-label">Recommended</div>}
          </div>
          {
            type === 'Free' ?
              <button className="subscription-button selected">Free plan</button> :
              <button className={classNames('subscription-button', {'selected': type === selectedSubscription})} onClick={() => toggleUpgradePlanModalOpen(true)}>{type === selectedSubscription ? 'Current Plan' : 'Upgrade'}</button>
          }
          <div className="subscription-options-list">
            {options.map((option) => (
              <div className="subscription-options" key={option}>
                <IoMdCheckmark className="checkmark-icon" />
                <p>{option}</p>
              </div>
            ))}
          </div>
        </div> :
        <div className="subscription-card">
          <div className="subscription-type">
            <h2>{type} {price}֏ {previousPrice && <span className="strikethrough-diagonal">{previousPrice}֏</span>}</h2>
            {isRecommendedLabelShown && <div className="recommended-label">Recommended</div>}
          </div>
          <button className="subscription-button" onClick={toLogin}>Get started</button>
          <div className="subscription-options-list">
            {options.map((option) => (
              <div className="subscription-options" key={option}>
                <IoMdCheckmark className="checkmark-icon" />
                <p>{option}</p>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}

export default SubscriptionCard;
