import React from "react";

import "./Index.scss"

function AboutUs() {
  return (
    <div className="about-us">
      <div className="about-us-content">
        <div className="about-us-content-section">
          <h1>About Us</h1>
          <p>Welcome to our innovative web application, where we harness the power of advanced speech recognition technology to revolutionize how you interact with text.</p>
        </div>
        <div className="about-us-content-section">
          <h2>Our Founders</h2>
          <p>Founded by Varuzhan Baghdasaryan and Davit Janvelyan in Yerevan, Armenia, our company is built on a foundation of scientific excellence and technological innovation.</p>
          <ul>
            <li><span className="about-us-section-bold-text">Varuzhan Baghdasaryan</span>: As an accomplished ML/AI researcher with a Ph.D. in computer science, Varuzhan brings extensive expertise and a
              passion for advancing speech recognition technology. His work has been instrumental in the development of our state-of-the-art Armenian ASR model, a significant achievement of the ArmSpeech project.</li>
            <li><span className="about-us-section-bold-text">Davit Janvelyan</span>: Co-founder Davit Janvelyan shares Varuzhan's vision for transforming voice-to-text capabilities.
              Together, they have created a platform that seamlessly integrates cutting-edge technology with user-friendly features, making document editing and transcription faster and more accessible.</li>
          </ul>
        </div>
        <div className="about-us-content-section">
          <h2>Our Mission</h2>
          <p>Our mission is to provide a powerful, reliable, and efficient tool for voice-to-text conversion. We aim to enhance productivity and accessibility by offering:</p>
          <ul>
            <li><span className="about-us-section-bold-text">Multilingual Support</span>: Our platform supports Armenian and English, making it versatile and accessible to a diverse user base.</li>
            <li><span className="about-us-section-bold-text">Express Transcription Services</span>: Whether you need to transcribe audio files, video files, or live microphone streams,
              our models deliver quick and accurate results.</li>
            <li><span className="about-us-section-bold-text">Enhanced Features</span>: With optional punctuation and capitalization, our transcriptions are clear and easy to read, tailored to your preferences.</li>
          </ul>
        </div>
        <div className="about-us-content-section">
          <h2>Commitment to Excellence</h2>
          <p>At the heart of our company is a dedication to research and innovation. The Armenian ASR model, a product of the ArmSpeech project,
            exemplifies our commitment to advancing the field of speech recognition through scientific research.</p>
          <p>Join us on our journey to transform the way you interact with text. Discover the future of document editing and transcription with our advanced speech recognition technology.</p>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
