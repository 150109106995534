import React, {useEffect, useState} from "react";
import "./SetNewPassword.scss"
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { confirmResetPasswordRequest, resetPasswordRequest } from "../../redux/Auth/actions";
import LoaderButton from "../LoaderButton/Index";
import AuthErrorMessage from "../AuthErrorMessage/Index";
import usePrevious from "../../hooks/usePrevious";

function SetNewPassword({email}) {

  const {
    isConfirmResetPasswordSuccess,
    isConfirmResetPasswordError,
    confirmResetPasswordErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onTouched'
  });

  const confirmationCode = watch('confirmation_code');
  const password = watch('password');
  const dispatch = useDispatch();
  const prevIsConfirmResetPasswordSuccess = usePrevious(isConfirmResetPasswordSuccess);
  const prevIsConfirmResetPasswordError = usePrevious(isConfirmResetPasswordError);

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const [isVerifyPressed, setIsVerifyPressed] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('')
  const [isLoader, setIsLoader] = useState(false)

  useEffect(() => {
    if (isConfirmResetPasswordSuccess && isVerifyPressed && prevIsConfirmResetPasswordSuccess === false) {
      setIsResetSuccess(true);
      setIsLoader(false);
      setConfirmPasswordErrorMessage('');
    }
  }, [isConfirmResetPasswordSuccess, isVerifyPressed])

  useEffect(() => {
    if (isConfirmResetPasswordError && prevIsConfirmResetPasswordError === false) {
      setConfirmPasswordErrorMessage(confirmResetPasswordErrorMessage);
      setIsLoader(false);
    }
  }, [isConfirmResetPasswordError])

  function handleTogglePassword() {
    setIsPasswordShown(!isPasswordShown);
  }

  function handleToggleConfirmPassword() {
    setIsConfirmPasswordShown(!isConfirmPasswordShown);
  }

  function handleResendCode() {
    reset();
    const obj = {email};
    dispatch(resetPasswordRequest(obj))
  }
  function handleVerifyCode(data) {
    setIsVerifyPressed(true);
    const obj = {...data, email}
    delete obj.confirmPassword;
    dispatch(confirmResetPasswordRequest(obj));
    setIsLoader(true);
  }

  return (
    <div className="new-password">
      {isResetSuccess ?
        <div className="successfully-reset">
          <p>Password has been reset successfully.</p>
          <FaRegCheckCircle size="30" color="#0F0" />
        </div> :
        <>
          <div className="top">
            <h3>Verify your email</h3>
          </div>
          <div className="text">
            {`The confirmation code has been sent successfully to email ${email}. Please enter the 6-digit code to proceed.`}
          </div>
          <form onSubmit={handleSubmit(handleVerifyCode)}>
            <div className="inputs">
              <input
                {...register('confirmation_code', {
                  required: true,
                  minLength: {
                    value: 6,
                    message: 'The length should be exactly 6'
                  },
                  maxLength: {
                    value: 6,
                    message: 'The length should be exactly 6'
                  },
                })}
                type="text"
                placeholder="Verification code"
                className={errors?.confirmation_code ? 'input-error' : ''}
                autoComplete="off"
              />
              { errors?.confirmation_code && <div className="error-message">{errors?.confirmation_code?.message || 'Field is required'}</div> }
            </div>
            { confirmationCode?.length === 6 ?
              <>
                <div className="inputs">
                  <div className="password-input">
                    <input
                      {...register('password',{
                        required: 'Password is required',
                        pattern: {
                          value: /^(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{8,}$/,
                          message: 'Password should include at least one numeric value and one special character'
                        },
                        minLength: {
                          value: 8,
                          message: 'Minimum required length is 8'
                        },
                        maxLength: {
                          value: 64,
                          message: 'Maximum required length is 64'
                        }
                      })}
                      type={isPasswordShown ? 'text' : 'password'}
                      placeholder="Password"
                      className={errors?.password ? 'input-error' : ''}
                    />
                    <div className="eye-icon" onClick={handleTogglePassword}>
                      {isPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
                    </div>
                  </div>
                  { errors?.password && <div className="error-message">{errors?.password?.message || 'Field is required'}</div> }
                </div>
                <div className="inputs">
                  <div className="password-input">
                    <input
                      {...register('confirmPassword',{
                        required: 'Confirm Password is required',
                        validate: (value) =>
                          value === password || 'The passwords do not match'
                      })}
                      type={isConfirmPasswordShown ? 'text' : 'password'}
                      placeholder="Confirm password"
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      className={errors?.confirmPassword ? 'input-error' : ''}
                    />
                    <div className="eye-icon" onClick={handleToggleConfirmPassword}>
                      {isConfirmPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
                    </div>
                  </div>
                  { errors?.confirmPassword && <div className="error-message">{errors?.confirmPassword?.message || 'Field is required'}</div> }
                </div>
              </> : null
            }
            { confirmPasswordErrorMessage &&
                <AuthErrorMessage errorMessage={confirmPasswordErrorMessage} />
            }
            <div className="button">
              {
                isLoader ?
                  <LoaderButton buttonText="Verify code" /> :
                  <input type="submit" value="Verify code" />
              }
            </div>
            <div className="resend">
              <p onClick={handleResendCode}>Resend the code</p>
            </div>
          </form>
        </>
      }
    </div>
  )
}

export default SetNewPassword;
