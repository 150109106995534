import React, { useEffect } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccountRequest } from "../../redux/Auth/actions";
import usePrevious from "../../hooks/usePrevious";

function DeleteAccount({isDeleteAccountModalOpen, openConfirmDeleteModal, onClose}) {

  const {
    isDeleteAccountSuccess,
    isDeleteAccountError,
    deleteAccountErrorMessage,
  } = useSelector(state => state.auth)

  const dispatch = useDispatch()
  const prevIsDeleteAccountSuccess = usePrevious(isDeleteAccountSuccess)

  useEffect(() => {
    if (isDeleteAccountSuccess && prevIsDeleteAccountSuccess === false) {
      openConfirmDeleteModal(true);
      closeModal();
    }
  }, [isDeleteAccountSuccess])

  function closeModal() {
    onClose(false);
  }

  function deleteAccount() {
    dispatch(deleteAccountRequest());
  }

  return (
    <>
      <Modal
        className="confirm-email-update-modal"
        title={<h2 className="title">Delete Account</h2>}
        open={isDeleteAccountModalOpen}
        onCancel={closeModal}
        okText="Yes, delete"
        onOk={deleteAccount}
        centered
      >
        <p>This account will no longer be available, and all your saved data will be permanently deleted.</p>
      </Modal>
    </>
  )
}

export default DeleteAccount;
