import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadTxtRequest } from "../../redux/Document/actions";

import Switch from "react-switch";
import RecordRTC from 'recordrtc';
import Stopwatch from "../StopWatch/Index";
import AudioUpload from "../AudioUpload/Index";
import LanguageDropdown from "../LanguageDropdown/Index";
import UpgradePlan from "../../modals/UpgradePlan";

import { FaMicrophone } from "react-icons/fa";
import { FaRegStopCircle } from "react-icons/fa";
import { Button, Progress  } from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { CopyOutlined } from '@ant-design/icons';
import { CheckOutlined } from '@ant-design/icons';
import { BsFiletypeTxt } from "react-icons/bs";

import ArmenianFlag from "../../assets/images/Armenia.png"
import UkFlag from "../../assets/images/UK.png"
import RussianFlag from "../../assets/images/russia.png"

import usePrevious from "../../hooks/usePrevious";
import { io } from "socket.io-client";
import classNames from "classnames";
import { toast } from 'react-toastify';

import "./Express.scss"

function Express() {

  const languageData = [
    { id: 1, languageName: 'Armenian', languageFlag: ArmenianFlag, languageSlug: 'HY' },
    { id: 2, languageName: 'English', languageFlag: UkFlag, languageSlug: 'EN' },
    // { id: 3, languageName: 'Russian', languageFlag: RussianFlag, languageSlug: 'RU' }
  ];
  const defaultSelected = languageData[0];
  const {
    isDownloadTxtSuccess,
    isDownloadTxtError,
    downloadTxtErrorMessage,
    downloadTxt,
  } = useSelector(state => state.document)
  const {
    getSubscriptionPlan
  } = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const prevIsDownloadTxtSuccess = usePrevious(isDownloadTxtSuccess)
  const socketRef = useRef(null);

  const [microphone, setMicrophone] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordTranscribeSuccess, setIsRecordTranscribeSuccess] = useState(false);
  const [isRecordDisabled, setIsRecordDisabled] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [text, setText] = useState('');
  const [isTimerShown, setIsTimerShown] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [isFileReset, setIsFileReset] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({is_punct_capit: false})
  const [selectedLanguage, setSelectedLanguage] = useState(defaultSelected);
  const [isCopyButtonShown, setIsCopyButtonShown] = useState(false)
  const [isCopied, setIsCopied] = useState(false);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [subscriptionLimitErrorMessage, setSubscriptionLimitErrorMessage] = useState('');

  useEffect(() => {

    const handleBeforeUnload = (event) => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    }
  }, [])

  useEffect(() => {
    if (isDownloadTxtSuccess && prevIsDownloadTxtSuccess === false) {
      downloadDocument(downloadTxt);
    }
  }, [isDownloadTxtSuccess]);

  const captureMicrophone = async () => {
    if (!microphone) {
      try {
        const constraints = {
          video: false,  // Disable video
          audio: {
            autoGainControl: false,
            echoCancellation: true,
            noiseSuppression: true,
          }
        };
        const mic = await navigator.mediaDevices.getUserMedia(constraints);
        setMicrophone(mic);
        return mic;
      } catch (error) {
        alert('Unable to capture your microphone. Please check console logs.');
      }
    }
    return microphone;
  };

  const startRecording = async () => {
    if (!isRecording) {
      const mic = await captureMicrophone();
      if (mic) {
        const options = {
          type: 'audio',
          mimeType: 'audio/wav',
          numberOfAudioChannels: 1,
          checkForInactiveTracks: true,
          bufferSize: 16384,
          sampleRate: 16000,
          desiredSampRate: 16000,
          bitrate: 256000,
          disableLogs: true,
        };
        const newRecorder = new RecordRTC(mic, options);
        newRecorder.startRecording();
        setRecorder(newRecorder);
        setIsRecording(true);
        setIsUploadDisabled(true)
        toggleTimer(true);
      }
    } else {
      stopRecording();
    }
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const audioBlob = recorder.getBlob();
        handleRecordingStopped(audioBlob);
        releaseMicrophone();
        toggleTimer(false);
        onRecordingDisable(true);
        setIsRecording(false);
        setIsLoading(true);
      });
    }
  };

  const releaseMicrophone = () => {
    if (microphone) {
      microphone.getTracks().forEach(track => track.stop());
      setMicrophone(null);
    }
  };

  const handleRecordingStopped = (audioBlob) => {
    const formData = new FormData();
    formData.append('speech_audio', audioBlob, 'recording.wav');
    sendAudioBlob(audioBlob);
  };

  function fileUpload(audioBlob) {
    setIsLoading(true);
    setIsUploadDisabled(true)
    //setIsRecordTranscribeSuccess(false)
    //const formData = new FormData();
    //formData.append('speech_audio', audioBlob);
    //formData.append('is_upload', true)
    sendAudioBlob(audioBlob, true);
  }

  function sendAudioBlob(audioBlob, is_upload = false) {
    const token = localStorage.token;
    const { is_punct_capit } = selectedFilters;
    const { languageSlug } = selectedLanguage;
    const dataToSend = {
      token,
      audio_data: audioBlob,
      is_punct_capit,
      language: languageSlug,
      is_upload,
    };

    //socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {timeout: 86400, reconnectionDelay: 14400, reconnectionDelayMax: 43200});

    socketRef.current.on('connect', () => {
      socketRef.current.emit('start_transcribe', dataToSend);
      setText('');
      setSubscriptionLimitErrorMessage('');
      setIsRecordTranscribeSuccess(false)
      setIsCopyButtonShown(false)
    });

    socketRef.current.on('transcribe_error', (data) => {
      toast.error(data.message)
    });

    socketRef.current.on('limit_error', (data) => {
      toggleUpgradePlanModalOpen(true)
      setSubscriptionLimitErrorMessage(data.message);
    });

    socketRef.current.on('transcribe_progress', (data) => {
      if (data.percent === -1) {
        toast.error(data.transcription)
      } else {
        const percent = +data.percent.toFixed(2);
        setProgress(percent);
        setText(prev => prev ? `${prev} ${data.transcription}` : data.transcription);
        // setText(prev => {
        //  const shouldAddSpace = prev && !data.transcription.startsWith(',');
        //  return shouldAddSpace ? `${prev} ${data.transcription}` : `${prev}${data.transcription}`;
        //});
        setIsRecordTranscribeSuccess(true);
      }
    });

    socketRef.current.on('disconnect', () => {
      setTimeout(() => {
        setProgress(0);
        setIsCopyButtonShown(true)
        setIsLoading(false);
        setIsUploadDisabled(false);
        onRecordingDisable(false);
        fileReset(true);
        socketRef.current.disconnect();
      }, 1000)
    });
  }

  function changeLanguage(language) {
    setSelectedLanguage(language)
  }

  function inputChangeHandler(checked) {
    setSelectedFilters({...selectedFilters, is_punct_capit: checked})
  }

  function onStopDueDuration(isStopped) {
    if (isStopped) {
      stopRecording();
    }
  }

  function toggleTimer(isTimer) {
    setIsTimerShown(isTimer);
    setIsTimerStarted(isTimer);
  }

  function onRecordingDisable(isDisabled) {
    setIsRecordDisabled(isDisabled);
  }

  function fileReset(isReset) {
    setIsFileReset(isReset);
  }

  function onDurationShown(isDuration) {
    if (isDuration) {
      toast.error('Maximum 1 hour');
    }
  }

  function onFormatShown(isFormat) {
    if (isFormat) {
      toast.error('Not supported format (only MP3, WAV, MP4)');
    }
  }

  function onSizeShown(isSize) {
    if (isSize) {
      toast.error('Maximum 180 MB');
    }
  }

  function onCopy() {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500)
  }

  function convert() {
    const obj = {transcription: text}
    dispatch(downloadTxtRequest(obj));
  }

  function downloadDocument(downloadLink) {
    const documentBlob = new Blob([downloadLink], { type: 'text/plain' });
    const url = window.URL.createObjectURL(documentBlob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'Untitled.txt';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url)
  }

  function toggleUpgradePlanModalOpen(isOpen) {
    setIsUpgradePlanModalOpen(isOpen)
  }

  return (
    <div className="express">
      <div className={classNames('music', {'playing': isRecording})}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div>
        <div className="data">
          <div className="record">
            <div
              className={classNames({
                'mic-icon': isRecording,
                'initial-icon': !isRecording,
                'disable': isRecordDisabled
              })}
              onClick={startRecording}>
              {isRecording ?
                <FaRegStopCircle className="audio-icons stop-icon"/> :
                <FaMicrophone className="audio-icons recording-icon"/>
              }
            </div>
            <div className="upload">
              <AudioUpload
                isUploadDisabled={isUploadDisabled} isFileReset={isFileReset} onRecordingDisable={onRecordingDisable} onUpload={fileUpload} onResetFile={fileReset}
                onDurationShown={onDurationShown} onFormatShown={onFormatShown} onSizeShown={onSizeShown}
              />
            </div>
          </div>
          {isTimerShown ?
            <Stopwatch isStarted={isTimerStarted} onRecordingStop={onStopDueDuration}/> :
            <div className="filter">
              <div className="language-drop-down">
                <LanguageDropdown items={languageData} defaultSelected={selectedLanguage} onChangeItem={changeLanguage} />
              </div>
              <span onClick={() => inputChangeHandler(!selectedFilters.is_punct_capit)}>Grammar</span>
              <Switch onChange={inputChangeHandler} checked={selectedFilters.is_punct_capit} className="react-switch"/>
            </div>
          }
        </div>
        {isLoading ?
          <div className="express-loader-container">
            <Progress type="circle" percent={progress} />
          </div> : null
        }
        {/*{isRecordTranscribeSuccess && !isRecording && !isRecordDisabled &&*/}
        {isRecordTranscribeSuccess && !isRecording &&
          <div className="response-data">
            {text &&
              <>
                <h2 className="resp-text">{text}</h2>
                {isCopyButtonShown ?
                  <div className="copy-button-container">
                    <CopyToClipboard text={text} onCopy={onCopy}>
                      <Button
                        type="primary"
                        icon={isCopied ? <CheckOutlined className="copy-button-icons" /> : <CopyOutlined className="copy-button-icons" />}
                        size="large"
                        className={classNames('copy-button', {'disable': isUploadDisabled})}
                      >
                        {isCopied ? 'Copied' : 'Copy'}
                      </Button>
                    </CopyToClipboard>
                    <BsFiletypeTxt className="download-icon" onClick={convert} />
                  </div> : null
                }
              </>
            }
            {!text && progress === 100 && <h2 className="error-text">NO SPEECH DETECTED</h2>}
          </div>
        }
      </div>
      <UpgradePlan isUpgradePlanModalOpen={isUpgradePlanModalOpen} currentSubscriptionPlan={getSubscriptionPlan} subscriptionLimitErrorMessage={subscriptionLimitErrorMessage} onClose={toggleUpgradePlanModalOpen} />
    </div>
  )
}

export default Express;
