import {
  CREATE_DOCUMENT_ERROR,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  RESET_DOCUMENTS_LIST,
  DELETE_DOCUMENT_ERROR,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  GET_CURRENT_DOCUMENT_ERROR,
  GET_CURRENT_DOCUMENT_REQUEST,
  GET_CURRENT_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_LIST_ERROR,
  GET_DOCUMENTS_LIST_REQUEST,
  GET_DOCUMENTS_LIST_SUCCESS,
  UPDATE_DOCUMENT_ERROR,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  DOWNLOAD_PDF_DOCUMENT_REQUEST,
  DOWNLOAD_PDF_DOCUMENT_SUCCESS,
  DOWNLOAD_PDF_DOCUMENT_ERROR,
  DOWNLOAD_DOC_DOCUMENT_REQUEST,
  DOWNLOAD_DOC_DOCUMENT_SUCCESS,
  DOWNLOAD_DOC_DOCUMENT_ERROR,
  UPDATE_DOCUMENT_TITLE_REQUEST,
  UPDATE_DOCUMENT_TITLE_SUCCESS,
  UPDATE_DOCUMENT_TITLE_ERROR,
  DOWNLOAD_TXT_REQUEST,
  DOWNLOAD_TXT_SUCCESS,
  DOWNLOAD_TXT_ERROR
} from "./types";

const initialState = {
  isGetDocumentsListSuccess: false,
  isGetDocumentsListError: false,
  getDocumentsListErrorMessage: '',
  fetchDocuments: [],
  isCreateDocumentSuccess: false,
  isCreateDocumentError: false,
  createDocumentErrorMessage: '',
  createDocument: {},
  isDeleteDocumentSuccess: false,
  isDeleteDocumentError: false,
  deleteDocumentErrorMessage: '',
  deleteDocument: {},
  isCurrentDocumentSuccess: false,
  isCurrentDocumentError: false,
  currentDocumentErrorMessage: '',
  currentDocument: {},
  isUpdateDocumentTitleSuccess: false,
  isUpdateDocumentTitleError: false,
  updateDocumentTitleErrorMessage: '',
  updateDocumentTitle: {},
  isUpdateDocumentSuccess: false,
  isUpdateDocumentError: false,
  updateDocumentErrorMessage: '',
  updateDocument: {},
  isDownloadPdfDocumentSuccess: false,
  isDownloadPdfDocumentError: false,
  downloadPdfDocumentErrorMessage: '',
  downloadPdfDocument: {},
  isDownloadDocDocumentSuccess: false,
  isDownloadDocDocumentError: false,
  downloadDocDocumentErrorMessage: '',
  downloadDocDocument: {},
  isDownloadTxtSuccess: false,
  isDownloadTxtError: false,
  downloadTxtErrorMessage: '',
  downloadTxt: {},
}

export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_LIST_REQUEST:
      return {
        ...state,
        isGetDocumentsListSuccess: false,
        isGetDocumentsListError: false,
        GetDocumentsListErrorMessage: '',
        fetchDocuments: [],
      }
    case GET_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        isGetDocumentsListSuccess: true,
        isGetDocumentsListError: false,
        GetDocumentsListErrorMessage: '',
        fetchDocuments: action.payload,
      }
    case GET_DOCUMENTS_LIST_ERROR:
      return {
        ...state,
        isGetDocumentsListSuccess: false,
        isGetDocumentsListError: true,
        GetDocumentsListErrorMessage: action.payload,
        fetchDocuments: [],
      }
    case RESET_DOCUMENTS_LIST:
      return {
        ...state,
        isGetDocumentsListSuccess: false,
        isGetDocumentsListError: false,
        GetDocumentsListErrorMessage: '',
        fetchDocuments: [],
      }

    case CREATE_DOCUMENT_REQUEST:
      return {
        ...state,
        isCreateDocumentSuccess: false,
        isCreateDocumentError: false,
        createDocumentErrorMessage: '',
        createDocument: {},
      }
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isCreateDocumentSuccess: true,
        isCreateDocumentError: false,
        createDocumentErrorMessage: '',
        createDocument: action.payload,
      }
    case CREATE_DOCUMENT_ERROR:
      return {
        ...state,
        isCreateDocumentSuccess: false,
        isCreateDocumentError: true,
        createDocumentErrorMessage: action.payload,
        createDocument: {},
      }

    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        isDeleteDocumentSuccess: false,
        isDeleteDocumentError: false,
        deleteDocumentErrorMessage: '',
        deleteDocument: {},
      }
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDeleteDocumentSuccess: true,
        isDeleteDocumentError: false,
        deleteDocumentErrorMessage: '',
        deleteDocument: action.payload,
      }
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        isDeleteDocumentSuccess: false,
        isDeleteDocumentError: true,
        deleteDocumentErrorMessage: action.payload,
        deleteDocument: {},
      }

    case GET_CURRENT_DOCUMENT_REQUEST:
      return {
        ...state,
        isCurrentDocumentSuccess: false,
        isCurrentDocumentError: false,
        currentDocumentErrorMessage: '',
        currentDocument: {},
      }
    case GET_CURRENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        isCurrentDocumentSuccess: true,
        isCurrentDocumentError: false,
        currentDocumentErrorMessage: '',
        currentDocument: action.payload,
      }
    case GET_CURRENT_DOCUMENT_ERROR:
      return {
        ...state,
        isCurrentDocumentSuccess: false,
        isCurrentDocumentError: true,
        currentDocumentErrorMessage: action.payload,
        currentDocument: {},
      }

    case UPDATE_DOCUMENT_TITLE_REQUEST:
      return {
        ...state,
        isUpdateDocumentTitleSuccess: false,
        isUpdateDocumentTitleError: false,
        updateDocumentTitleErrorMessage: '',
        updateDocumentTitle: {},
      }
    case UPDATE_DOCUMENT_TITLE_SUCCESS:
      return {
        ...state,
        isUpdateDocumentTitleSuccess: true,
        isUpdateDocumentTitleError: false,
        updateDocumentTitleErrorMessage: '',
        updateDocumentTitle: action.payload,
      }
    case UPDATE_DOCUMENT_TITLE_ERROR:
      return {
        ...state,
        isUpdateDocumentTitleSuccess: false,
        isUpdateDocumentTitleError: true,
        updateDocumentTitleErrorMessage: action.payload,
        updateDocumentTitle: {},
      }

    case UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        isUpdateDocumentSuccess: false,
        isUpdateDocumentError: false,
        updateDocumentErrorMessage: '',
        updateDocument: {},
      }
    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isUpdateDocumentSuccess: true,
        isUpdateDocumentError: false,
        updateDocumentErrorMessage: '',
        updateDocument: action.payload,
      }
    case UPDATE_DOCUMENT_ERROR:
      return {
        ...state,
        isUpdateDocumentSuccess: false,
        isUpdateDocumentError: true,
        updateDocumentErrorMessage: action.payload,
        updateDocument: {},
      }

    case DOWNLOAD_PDF_DOCUMENT_REQUEST:
      return {
        ...state,
        isDownloadPdfDocumentSuccess: false,
        isDownloadPdfDocumentError: false,
        downloadPdfDocumentErrorMessage: '',
        downloadPdfDocument: {},
      }
    case DOWNLOAD_PDF_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDownloadPdfDocumentSuccess: true,
        isDownloadPdfDocumentError: false,
        downloadPdfDocumentErrorMessage: '',
        downloadPdfDocument: action.payload,
      }
    case DOWNLOAD_PDF_DOCUMENT_ERROR:
      return {
        ...state,
        isDownloadPdfDocumentSuccess: false,
        isDownloadPdfDocumentError: true,
        downloadPdfDocumentErrorMessage: action.payload,
        downloadPdfDocument: {},
      }

    case DOWNLOAD_DOC_DOCUMENT_REQUEST:
      return {
        ...state,
        isDownloadDocDocumentSuccess: false,
        isDownloadDocDocumentError: false,
        downloadDocDocumentErrorMessage: '',
        downloadDocDocument: {},
      }
    case DOWNLOAD_DOC_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDownloadDocDocumentSuccess: true,
        isDownloadDocDocumentError: false,
        downloadDocDocumentErrorMessage: '',
        downloadDocDocument: action.payload,
      }
    case DOWNLOAD_DOC_DOCUMENT_ERROR:
      return {
        ...state,
        isDownloadDocDocumentSuccess: false,
        isDownloadDocDocumentError: true,
        downloadDocDocumentErrorMessage: action.payload,
        downloadDocDocument: {},
      }

    case DOWNLOAD_TXT_REQUEST:
      return {
        ...state,
        isDownloadTxtSuccess: false,
        isDownloadTxtError: false,
        downloadTxtErrorMessage: '',
        downloadTxt: {},
      }
    case DOWNLOAD_TXT_SUCCESS:
      return {
        ...state,
        isDownloadTxtSuccess: true,
        isDownloadTxtError: false,
        downloadTxtErrorMessage: '',
        downloadTxt: action.payload,
      }
    case DOWNLOAD_TXT_ERROR:
      return {
        ...state,
        isDownloadTxtSuccess: false,
        isDownloadTxtError: true,
        downloadTxtErrorMessage: action.payload,
        downloadTxt: {},
      }
    default:
      return state
  }
}
