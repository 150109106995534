import React, { useState, useEffect, useRef } from 'react';

import "./Index.scss"

function Stopwatch({ isStarted, onRecordingStop, isSmall = false }) {
  const [time, setTime] = useState(0);
  const intervalRef = useRef();

  useEffect(() => {
    isStarted ? startStopwatch() : clearInterval(intervalRef.current);
  }, [isStarted]);

  const startStopwatch = () => {
    let startTime = Date.now() - time;
    intervalRef.current = setInterval(() => {
      setTime(Date.now() - startTime);
    }, 10);
  };

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    const milliseconds = ms % 1000;
    if (minutes >= 60) {
      onRecordingStop(true);
    }
    return `${minutes}:${seconds.toString().padStart(2, '0')}.${milliseconds
      .toString()
      .padStart(3, '0')}`;
  };

  return (
    <div>
      { isSmall ?
        <p className="small-timer-text">{formatTime(time)}</p> :
        <h2 className="timer-text">{formatTime(time)}</h2>
      }
    </div>
  );
}

export default Stopwatch;
