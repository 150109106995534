import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom"
import { routes } from "./routing";
import { useDispatch, useSelector } from "react-redux";
import { logOut, setToken } from "./redux/Auth/saga";
import { getSubscriptionPlanNameRequest } from "./redux/Auth/actions";
import ProtectedRoute from "./routing/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from 'react-responsive'
import SmallScreenResolution from "./components/SmallScreenResolution/Index";

import './App.scss';

const potentialToken = localStorage.token;
if (potentialToken) {
  setToken(potentialToken);
}

function App() {

  const {
    isCurrentUserError,
    currentUserErrorMessage,
  } = useSelector(state => state.auth)

  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  useEffect(() => {
    if (potentialToken) {
      dispatch(getSubscriptionPlanNameRequest());
    }
  }, [])

  useEffect(() => {
    if (isCurrentUserError) {
      logOut();
    }
  }, [isCurrentUserError])

  return (
    <div className="app">
      {isMobile ?
        <SmallScreenResolution /> :
        <>
          <Routes>
          {routes.map(({path, element, loginProtectedRoute, userProtectedRoute, children }) => (
            <Route element={<ProtectedRoute loginProtectedRoute={loginProtectedRoute} userProtectedRoute={userProtectedRoute} />} key={path}>
              <Route path={path} element={element}>
                {children?.map((child) => (
                  <Route index={child.index} path={child.path} element={child.element} key={child.path || child.index} />
                ))}
              </Route>
            </Route>
          ))}
          </Routes>
          <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          theme="colored"
          />
        </>
      }
    </div>
  );
}

export default App;
