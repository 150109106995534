import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import SubscriptionCard from "../SubscriptionCard/Index";
import UpgradePlan from "../../modals/UpgradePlan";
import Loader from "../Loader";

import "./Subscription.scss"

const subscriptionPlans = [
  {
    id: 0,
    type: 'Free',
    price: '0',
    options: [
      'Enjoy 1 free hour of transcription time per month.',
      'Transcribe up to 5 audio and video files (MP3, WAV, MP4) per month.',
      'Create up to 5 documents.',
      'Download documents and transcriptions in DOCX, PDF, and TXT formats.',
      'Transcription available in 2 languages (Armenian and English) with punctuation and capitalization features.',
      'Maximum 1 hour duration per conversation and file upload.',
    ],
  },
  {
    id: 1,
    type: 'Standard',
    price: '3500',
    options: [
      'Enjoy 5 hours of transcription time per month.',
      'Transcribe unlimited audio and video files (MP3, WAV, MP4) per month.',
      'Create up to 50 documents.',
      'Download documents and transcriptions in DOCX, PDF, and TXT formats.',
      'Transcription available in 2 languages (Armenian and English) with punctuation and capitalization features.',
      'Maximum 1 hour duration per conversation and file upload.',
    ],
  },
  {
    id: 2,
    type: 'Premium',
    price: '6000',
    previousPrice: '7000',
    options: [
      'Enjoy 50 hours of transcription time per month.',
      'Transcribe unlimited audio and video files (MP3, WAV, MP4) per month.',
      'Create up to 100 documents.',
      'Download documents and transcriptions in DOCX, PDF, and TXT formats.',
      'Transcription available in 2 languages (Armenian and English) with punctuation and capitalization features.',
      'Maximum 1 hour duration per conversation and file upload.',
    ],
    isRecommendedLabelShown: false,
  }
];

function Subscription() {

  const {
    getSubscriptionPlan
  } = useSelector(state => state.auth)

  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    if (getSubscriptionPlan) {
      setIsLoader(false);
    }
  }, [getSubscriptionPlan])

  function toggleUpgradePlanModalOpen(isOpen) {
    setIsUpgradePlanModalOpen(isOpen)
  }

  return (
    <>
      {isLoader ?
        <Loader /> :
        <div className="subscription">
          <div className="subscription-title">
            <h1>Subscription</h1>
            <p>Unlock Your Transcription Potential: Choose Your Subscription Plan.</p>
          </div>
          <div className="subscription-plan-container">
            {subscriptionPlans.map((subscription) => (
              <div className="subscription-plans" key={subscription.id}>
                <SubscriptionCard {...subscription} selectedSubscription={getSubscriptionPlan} toggleUpgradePlanModalOpen={toggleUpgradePlanModalOpen} />
              </div>
            ))}
          </div>
          <UpgradePlan isUpgradePlanModalOpen={isUpgradePlanModalOpen} currentSubscriptionPlan={getSubscriptionPlan} isNavigationDisabled onClose={toggleUpgradePlanModalOpen} />
        </div>
      }
    </>
  )
}

export default Subscription;
