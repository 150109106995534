import React from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { authLogoutRequest } from "../../redux/Auth/actions";

function LogoutModal({isLogoutModalOpen, onClose}) {

  const dispatch = useDispatch();

  function closeModal() {
    onClose(false)
  }

  function signOut() {
    dispatch(authLogoutRequest())
  }

  return (
    <>
      <Modal
        className="logout-modal"
        title={<h2 className="title">Are you sure you want to sign out ?</h2>}
        open={isLogoutModalOpen}
        onCancel={closeModal}
        okText="Yes, sign out"
        onOk={signOut}
        centered
      >
      </Modal>
    </>
  )
}

export default LogoutModal;
