import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allDevicesLogoutRequest,
  currentUserRequest,
  fetchSubscriptionPlanRequest,
  resetSubscriptionPlan
} from "../../redux/Auth/actions";
import { Progress } from 'antd';
import FullName from "../../modals/ProfileUpdate/FullName";
import Password from "../../modals/ProfileUpdate/Password";
import Email from "../../modals/ProfileUpdate/Email";
import ConfirmEmail from "../../modals/ProfileUpdate/ConfirmEmail";
import DeleteAccount from "../../modals/ProfileDelete/DeleteAccount";
import ConfirmDeleteAccount from "../../modals/ProfileDelete/ConfirmDeleteAccount";
import DeletedAccountMessage from "../../modals/ProfileDelete/DeletedAccountMessage";
import Loader from "../Loader";

import "./Profile.scss";

function Profile() {

  const {
    isCurrentUserSuccess,
    isCurrentUserError,
    currentUserErrorMessage,
    currentUser,
    isGetSubscriptionPlanSuccess,
    isGetSubscriptionPlanError,
    getSubscriptionPlanErrorMessage,
    fetchSubscriptionPlan,
  } = useSelector(state => state.auth)

  const dispatch = useDispatch();

  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
  const [isChangeConfirmEmailModalOpen, setIsChangeConfirmEmailModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const [isConfirmDeleteAccountModalOpen, setIsConfirmDeleteAccountModalOpen] = useState(false);
  const [isDeletedAccountMessageModalOpen, setIsDeletedAccountMessageModalOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [updatedEmailData, setUpdatedEmailData] = useState();
  const [email, setEmail] = useState('');
  const [progress, setProgress] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchSubscriptionPlanRequest());
    dispatch(currentUserRequest());

    return () => {
      dispatch(resetSubscriptionPlan());
    }

  }, [])

  useEffect(() => {
    if (isCurrentUserSuccess && isGetSubscriptionPlanSuccess) {
      setIsLoader(false);
    }
  }, [isCurrentUserSuccess, isGetSubscriptionPlanSuccess])

  useEffect(() => {
    if (isCurrentUserSuccess) {
      // setFullName(`${currentUser.firstName} ${currentUser.lastName}`);
      setFullName(currentUser.full_name);
      setEmail(currentUser.email);
    }
  }, [isCurrentUserSuccess])

  useEffect(() => {
    // const userTimeZone = getUserTimeZone();
    // const localStartDateString = convertUTCToLocalTime('2024-06-15 09:30:19.914511', userTimeZone);
    // console.log(localStartDateString);
    if (isGetSubscriptionPlanSuccess) {
      const userTimeZone = getUserTimeZone();
      const localStartDateString = convertUTCToLocalTime(fetchSubscriptionPlan?.start_date, userTimeZone);
      const localEndDateString = convertUTCToLocalTime(fetchSubscriptionPlan?.end_date, userTimeZone);
      setStartDate(localStartDateString);
      setEndDate(localEndDateString);
      setProgress(Math.floor((fetchSubscriptionPlan?.used_seconds * 100) / fetchSubscriptionPlan?.max_seconds));
    }
  }, [isGetSubscriptionPlanSuccess])

  const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const convertUTCToLocalTime = (utcDateString, timeZone) => {
    const utcDate = new Date(utcDateString);
    return utcDate.toLocaleString('en-GB', { timeZone });
  };

  function toggleChangeNameModal(isOpen) {
    setIsChangeNameModalOpen(isOpen);
  }

  function toggleChangeEmailModal(isOpen) {
    setIsChangeEmailModalOpen(isOpen);
  }

  function toggleChangeConfirmEmailModal(isOpen) {
    setIsChangeConfirmEmailModalOpen(isOpen)
  }

  function toggleChangePasswordModal(isOpen) {
    setIsChangePasswordModalOpen(isOpen);
  }

  function toggleDeleteAccountModal(isOpen) {
    setIsDeleteAccountModalOpen(isOpen)
  }

  function toggleConfirmDeleteAccountModal(isOpen) {
    setIsConfirmDeleteAccountModalOpen(isOpen)
  }

  function toggleDeletedAccountMessageModal(isOpen) {
    setIsDeletedAccountMessageModalOpen(isOpen);
  }

  function signOut() {
    dispatch(allDevicesLogoutRequest());
  }

  function updateFullName(updatedName) {
    setFullName(updatedName);
  }

  function updateEmailData(updatedEmail) {
    setUpdatedEmailData(updatedEmail)
    toggleChangeConfirmEmailModal(true);
  }

  function confirmedEmail(updatedEmail) {
    setEmail(updatedEmail);
  }

  return (
    <>
      {isLoader ?
        <Loader /> :
        <div className="profile">
          <div className="full-name">
            <h2>Account Details</h2>
          </div>
          <h2>Profile</h2>
          <div className="data">
            <div className="info">
              Name
            </div>
            <div className="update">
              <p>{fullName}</p>
              <span onClick={() => toggleChangeNameModal(true)}>Update</span>
            </div>
          </div>
          <div className="data">
            <div className="info">
              Email
            </div>
            <div className="update">
              <p>{email}</p>
              <span onClick={() => toggleChangeEmailModal(true)}>Update</span>
            </div>
          </div>
          <div className="data">
            <div className="info">
              Password
            </div>
            <div className="update">
              <p>••••••••</p>
              <span onClick={() => toggleChangePasswordModal(true)}>Update</span>
            </div>
          </div>
          <div className="delete-account">
            <span onClick={() => toggleDeleteAccountModal(true)}>Delete Account</span>
          </div>
          <div className="subscription-plan-content">
            <div className="subscription-title">
              <h2>Current Subscription Plan</h2>
            </div>
            <div className="subscription-name">
              <p>Name: {fetchSubscriptionPlan?.plan}</p>
            </div>
            <div className="subscription-progress-container">
              <div className="subscription-progress">
                <div>Usage:</div>
                <div>
                  <Progress percent={progress} strokeColor="#2551da" trailColor="#A3C1E0" size={[500, 15]} />
                </div>
                <p>({fetchSubscriptionPlan?.used_seconds?.toFixed(1)} / {fetchSubscriptionPlan?.max_seconds} seconds)</p>
              </div>
              <div className="subscription-plan-remaining">
                <p>Uploads (WAV, MP3, MP4): {fetchSubscriptionPlan?.plan === 'Free' ? <span>{fetchSubscriptionPlan?.used_uploads} / {fetchSubscriptionPlan?.max_uploads}</span> : 'Unlimit'}</p>
                <p>Documents: {fetchSubscriptionPlan?.created_docs} / {fetchSubscriptionPlan?.max_docs}</p>
                {
                  fetchSubscriptionPlan?.plan !== 'Free' &&
                  <div className="subscription-expiration">
                    <p>Start Date: {startDate}</p>
                    <p>End Date: {endDate}</p>
                  </div>
                }
              </div>
            </div>
          </div>

          {isChangeNameModalOpen ? <FullName isChangeNameModalOpen={isChangeNameModalOpen} fullName={fullName} updateFullName={updateFullName} onClose={toggleChangeNameModal} /> : null}
          {isChangeEmailModalOpen ? <Email isChangeEmailModalOpen={isChangeEmailModalOpen} updateEmailData={updateEmailData} onClose={toggleChangeEmailModal} /> : null}
          {isChangeConfirmEmailModalOpen ? <ConfirmEmail isChangeConfirmEmailModalOpen={isChangeConfirmEmailModalOpen} email={email} updatedEmailData={updatedEmailData} confirmedEmail={confirmedEmail} onClose={toggleChangeConfirmEmailModal} /> : null}
          {isChangePasswordModalOpen ? <Password isChangePasswordModalOpen={isChangePasswordModalOpen} onClose={toggleChangePasswordModal} /> : null}
          {isDeleteAccountModalOpen ? <DeleteAccount isDeleteAccountModalOpen={isDeleteAccountModalOpen} openConfirmDeleteModal={toggleConfirmDeleteAccountModal} onClose={toggleDeleteAccountModal} /> : null}
          {isConfirmDeleteAccountModalOpen ? <ConfirmDeleteAccount isConfirmDeleteAccountModalOpen={isConfirmDeleteAccountModalOpen} email={email} openDeletedMessageModal={toggleDeletedAccountMessageModal} onClose={toggleConfirmDeleteAccountModal} /> : null}
          {isDeletedAccountMessageModalOpen ? <DeletedAccountMessage isDeletedAccountMessageModalOpen={isDeletedAccountMessageModalOpen} /> : null}
        </div>
      }
    </>
  )
}

export default Profile;
