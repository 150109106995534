import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { confirmUpdateEmailRequest, updateEmailRequest } from "../../redux/Auth/actions";
import usePrevious from "../../hooks/usePrevious";

import LoaderButton from "../../components/LoaderButton/Index";

import "./ConfirmEmail.scss"
import AuthErrorMessage from "../../components/AuthErrorMessage/Index";

function ConfirmEmail({isChangeConfirmEmailModalOpen, email, updatedEmailData, confirmedEmail, onClose}) {

  const {
    isConfirmUpdateEmailSuccess,
    isConfirmUpdateEmailError,
    confirmUpdateEmailErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onTouched'
  });

  const dispatch = useDispatch();
  const prevIsConfirmUpdateEmailSuccess = usePrevious(isConfirmUpdateEmailSuccess);
  const prevIsConfirmUpdateEmailError = usePrevious(isConfirmUpdateEmailError)

  const [isLoader, setIsLoader] = useState(false);
  const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState('');

  useEffect(() => {
    if (isConfirmUpdateEmailSuccess && prevIsConfirmUpdateEmailSuccess === false) {
      confirmedEmail(updatedEmailData.new_email);
      closeModal();
      setIsLoader(false);
      setConfirmEmailErrorMessage('');
    }
  }, [isConfirmUpdateEmailSuccess])

  useEffect(() => {
    if (isConfirmUpdateEmailError && prevIsConfirmUpdateEmailError === false) {
      setConfirmEmailErrorMessage(confirmUpdateEmailErrorMessage)
      setIsLoader(false);
    }
  }, [isConfirmUpdateEmailError])

  function closeModal() {
    onClose(false)
  }

  function handleResendCode() {
    dispatch(updateEmailRequest(updatedEmailData))
    reset();
  }

  function updateSubmit(data) {
    dispatch(confirmUpdateEmailRequest(data))
    setIsLoader(true);
  }

  return (
    <>
      <Modal
        className="confirm-email-update-modal"
        title={<h2 className="title">Confirmation code</h2>}
        open={isChangeConfirmEmailModalOpen}
        onCancel={closeModal}
        footer={null}
        centered
      >
      <div className="text">
        {`The confirmation code has been sent successfully to email ${email}. Please enter the 6-digit code to proceed.`}
      </div>
        <form onSubmit={handleSubmit(updateSubmit)}>
          <div className="inputs">
            <input
              {...register('confirmation_code', {
                required: true,
                minLength: {
                  value: 6,
                  message: 'The length should be exactly 6'
                },
                maxLength: {
                  value: 6,
                  message: 'The length should be exactly 6'
                },
              })}
              type="text"
              placeholder="Verification code"
              className={errors?.confirmation_code ? 'input-error' : ''}
              autoComplete="off"
            />
            { errors?.confirmation_code && <div className="error-message">{errors?.confirmation_code?.message || 'Field is required'}</div> }
          </div>
          { confirmEmailErrorMessage &&
              <AuthErrorMessage errorMessage={confirmEmailErrorMessage} />
          }
          <div className="button">
            {
              isLoader ?
                <LoaderButton buttonText="Verify code" /> :
                <input type="submit" value="Verify code" />
            }
          </div>
        </form>
        <div className="resend">
          <p onClick={handleResendCode}>Resend the code</p>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmEmail;
