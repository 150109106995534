import React from "react";

import { Navigate } from "react-router-dom"
import { isAuth } from "../redux/Auth/saga";

import Main from "../components/Main/Main";
import Login from "../components/Login/Index";
import Pricing from "../pages/Pricing/Index";
import Product from "../pages/Product/Index";
import ContactUs from "../pages/ContactUs/Index";
import AboutUs from "../pages/AboutUs/Index";
import Dashboard from "../components/WorkSpace/Dashboard";
import Profile from "../components/WorkSpace/Profile";
import Subscription from "../components/WorkSpace/Subscription";
import Express from "../components/WorkSpace/Express";
import Contact from "../components/WorkSpace/Contact";
import WorkSpace from "../components/WorkSpace/Index";
import DocumentDetails from "../components/Document/Details";
import PrivacyPolicy from "../pages/PrivacyPolicy/Index";
import TermsConditions from "../pages/TermsConditions/Index";
import Youtube from "../components/Youtube/Index";
import Subtitle from "../components/Subtitle/Index";

export const routes = [
  {
    path: '',
    element: <Main />,
    userProtectedRoute: true,
    children: [
      {
        index: true,
        element: <Product />
      },
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
      {
        path: 'about-us',
        element: <AboutUs />
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'terms-conditions',
        element: <TermsConditions />,
      }
    ]
  },
  {
    path: '/login',
    element: <Login />,
    userProtectedRoute: true,
  },
  {
    path: '/workspace',
    element: <WorkSpace />,
    loginProtectedRoute: true,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'express',
        element: <Express />,
      },
      {
        path: 'youtube',
        element: <Youtube />,
      },
      {
        path: 'subtitle',
        element: <Subtitle />,
      },
      {
        path: 'subscription',
        element: <Subscription />,
      },
      {
        path: 'contact',
        element: <Contact />
      }
    ],
  },
  {
    path: '/document-details/:id',
    element: <DocumentDetails />,
    loginProtectedRoute: true,
  },
  {
    path: '*',
    element: <Navigate to={isAuth() ? '/workspace' : ''} />
  }
]