import React from "react";
import { isAuth } from "../redux/Auth/saga";
import { Outlet, Navigate } from "react-router-dom"

function ProtectedRoute({ loginProtectedRoute = false, userProtectedRoute = false }) {

  if (loginProtectedRoute) {
    return isAuth() ? <Outlet /> : <Navigate to="/login" />;
  }

  if (userProtectedRoute) {
    return isAuth() ? <Navigate to="/workspace" /> : <Outlet />;
  }

  return <Outlet />
}

export default ProtectedRoute;
