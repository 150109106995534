import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import SetNewPassword from "./SetNewPassword";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordRequest } from "../../redux/Auth/actions";
import usePrevious from "../../hooks/usePrevious";

import AuthErrorMessage from "../AuthErrorMessage/Index";
import LoaderButton from "../LoaderButton/Index";

import "./ResetPassword.scss"

function ResetPassword({backToLogin}) {

  const {
    isResetPasswordSuccess,
    isResetPasswordError,
    resetPasswordErrorMessage,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
  } = useForm({
    mode: 'onTouched'
  });

  const dispatch = useDispatch();
  const prevIsResetPasswordSuccess = usePrevious(isResetPasswordSuccess);
  const prevIsResetPasswordError = usePrevious(isResetPasswordError);

  const [email, setEmail] = useState('');
  const [isSendPressed, setIsSendPressed] = useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (isResetPasswordSuccess && isSendPressed && prevIsResetPasswordSuccess === false) {
      setIsNewPasswordShown(true);
      setIsLoader(false);
      setPasswordErrorMessage('');
    }
  }, [isResetPasswordSuccess, isSendPressed])

  useEffect(() => {
    if (isResetPasswordError && prevIsResetPasswordError === false) {
      setPasswordErrorMessage(resetPasswordErrorMessage);
      setIsLoader(false);
    }
  }, [isResetPasswordError])

  function handleBackToLogin() {
    backToLogin(false);
  }

  function handleSendCode(data) {
    setEmail(data.email);
    setIsSendPressed(true);
    const obj = {...data};
    dispatch(resetPasswordRequest(obj))
    setIsLoader(true);
  }

  return(
    <div className="forgot-password" key="forgot-password">
      { isNewPasswordShown ?
        <div key="new-password">
          <SetNewPassword email={email} />
        </div> :
        <div key="reset-password">
          <div className="top">
            <h3>Password Reset</h3>
            <p onClick={handleBackToLogin}>Back to sign-in</p>
          </div>
          <div className="text">
            Enter your email, and we’ll send you instructions on how to reset your password.
          </div>
          <form onSubmit={handleSubmit(handleSendCode)}>
            <div className="inputs">
              <input
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid email address'
                  },
                })}
                type="text"
                placeholder="Email"
                className={errors?.email ? 'input-error' : ''}
              />
              { errors?.email && <div className="error-message">{errors?.email?.message || 'Field is required'}</div> }
            </div>
            { passwordErrorMessage &&
                <AuthErrorMessage errorMessage={passwordErrorMessage} />
            }
            <div className="button">
              {
                isLoader ?
                  <LoaderButton buttonText="Send code" /> :
                  <input type="submit" value="Send code" />
              }
            </div>
          </form>
        </div>
      }
    </div>
  )
}

export default ResetPassword;
