import React from "react";

import "./Contact.scss"

function Contact() {

  return (
    <div className="contact">
      <div className="contact-content">
        <div className="contact-content-section">
          <h1>Contact Us</h1>
          <p>We'd love to hear from you! Whether you have questions, need support, or want to provide feedback, our team is here to assist you. Connect with us through any of the following channels:</p>
        </div>
        <div className="contact-content-section">
          <h2>Social Media</h2>
          <p>Stay updated with our latest news, features, and updates by following us on social media:</p>
          <ul>
            <li><span className="contact-us-section-bold-text">Facebook</span>: <a href="https://www.facebook.com/profile.php?id=61562177254598" target="_blank">Facebook page</a></li>
            <li><span className="contact-us-section-bold-text">Instagram</span>: <a href="https://www.instagram.com/talk2edit/" target="_blank">Instagram page</a></li>
          </ul>
        </div>
        <div className="contact-content-section">
          <h2>Email</h2>
          <p>For general inquiries, support, or feedback, feel free to email us at:</p>
          <ul>
            <li><span className="contact-section-bold-text">Email</span>: talk2edit.help@gmail.com</li>
          </ul>
        </div>
        <div className="contact-content-section">
          <h2>We're Here to Help</h2>
          <p>Our team is dedicated to providing excellent customer service and ensuring your experience with our web application is smooth and enjoyable. Don’t hesitate to reach out—we’re here to help!</p>
        </div>
      </div>
    </div>
  )
}

export default Contact;
