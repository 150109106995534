import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import createSagaMiddleware from "redux-saga"
import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "./redux/rootReducer";
import sagaWatcher from "./redux/rootSaga";
import { Provider } from "react-redux";
import axios from "axios";
import { getToken, isAuth, logOut } from "./redux/Auth/saga";

import './index.css';

axios.interceptors.request.use((config) => {
  config.headers.Authorization = isAuth() ? `Bearer ${getToken()}` : `${getToken()}`;
  return config;
})

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status >= 500)) {
      logOut();
    }
    return Promise.reject(error);
  }
)

const saga = createSagaMiddleware();
const store = createStore(rootReducer, undefined, compose(
  applyMiddleware(
    saga
  ),
  // window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined,
))

saga.run(sagaWatcher)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
