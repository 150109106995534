import React from 'react';

import classNames from 'classnames';

import "./Index.scss"

function RecordingAnimation({ isRecording }) {
    return (
        <div className={classNames('music', {'recording': isRecording})}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
    );
}

export default RecordingAnimation;