import React, { useState } from "react";
import Logo from "../../assets/images/logo_dashboard.png"
import { Outlet, NavLink } from "react-router-dom"
import { VscAccount } from "react-icons/vsc";
import {
  MdDashboard,
  MdSubscriptions,
  MdOutlinePermContactCalendar,
  MdOutlineSubtitles
} from "react-icons/md";
import { SiFastapi } from "react-icons/si";
import { PiSignOut } from "react-icons/pi";
import { FaYoutube } from "react-icons/fa";

import LogoutModal from "../../modals/Logout";

import "./Index.scss"

function WorkSpace() {

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  function toggleLogoutModal(isOpen) {
    setIsLogoutModalOpen(isOpen);
  }

  return (
    <div className="workspace">
      <div className="menu">
        <div>
          <NavLink to="" className="nav-link">
            <div className="logo">
              <img src={Logo} alt="Talk2Edit"/>
            </div>
          </NavLink>
          <div className="middle">
            <NavLink to="" className="nav-link" end>
              <div className="items">
                <MdDashboard className="icons" />
                <p>Dashboard</p>
              </div>
            </NavLink>
            <NavLink to="profile" className="nav-link">
              <div className="items">
                <VscAccount className="icons" />
                <p>Account</p>
              </div>
            </NavLink>
            <NavLink to="express" className="nav-link">
              <div className="items">
                <SiFastapi  className="icons" />
                <p>Express Mode</p>
              </div>
            </NavLink>
            <NavLink to="youtube" className="nav-link">
              <div className="items">
                <FaYoutube  className="icons" />
                <p>Youtube Link</p>
              </div>
            </NavLink>
            <NavLink to="subtitle" className="nav-link">
              <div className="items">
                <MdOutlineSubtitles  className="icons" />
                <p>Video Subtitle</p>
              </div>
            </NavLink>
            <NavLink to="subscription" className="nav-link">
              <div className="items">
                <MdSubscriptions className="icons" />
                <p>Subscription</p>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="bottom">
          <NavLink to="contact" className="nav-link">
            <div className="items">
              <MdOutlinePermContactCalendar className="icons" />
              <p>Contact Us</p>
            </div>
          </NavLink>
          <div className="items nav-link G-cursor-pointer" onClick={() => toggleLogoutModal(true)}>
            <PiSignOut className="icons" />
            <p>Sign Out</p>
          </div>
        </div>
      </div>
      <div className="layout">
        <Outlet />
      </div>
      <LogoutModal isLogoutModalOpen={isLogoutModalOpen} onClose={toggleLogoutModal} />
    </div>
  )
}

export default WorkSpace
