import {
  CREATE_DOCUMENT_ERROR,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  RESET_DOCUMENTS_LIST,
  DELETE_DOCUMENT_ERROR,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DOWNLOAD_PDF_DOCUMENT_ERROR,
  DOWNLOAD_PDF_DOCUMENT_REQUEST,
  DOWNLOAD_PDF_DOCUMENT_SUCCESS,
  DOWNLOAD_DOC_DOCUMENT_REQUEST,
  DOWNLOAD_DOC_DOCUMENT_SUCCESS,
  DOWNLOAD_DOC_DOCUMENT_ERROR,
  GET_CURRENT_DOCUMENT_ERROR,
  GET_CURRENT_DOCUMENT_REQUEST,
  GET_CURRENT_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_LIST_ERROR,
  GET_DOCUMENTS_LIST_REQUEST,
  GET_DOCUMENTS_LIST_SUCCESS,
  UPDATE_DOCUMENT_ERROR,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_TITLE_REQUEST,
  UPDATE_DOCUMENT_TITLE_SUCCESS,
  UPDATE_DOCUMENT_TITLE_ERROR,
  DOWNLOAD_TXT_REQUEST,
  DOWNLOAD_TXT_SUCCESS,
  DOWNLOAD_TXT_ERROR,
} from "./types";

export const fetchDocumentsRequest = () => ({
  type: GET_DOCUMENTS_LIST_REQUEST,
})

export const fetchDocumentsSuccess = (payload) => ({
  type: GET_DOCUMENTS_LIST_SUCCESS,
  payload,
})

export const fetchDocumentsError = (payload) => ({
  type: GET_DOCUMENTS_LIST_ERROR,
  payload,
})

export const resetDocumentsList = () => ({
  type: RESET_DOCUMENTS_LIST,
})


export const createDocumentRequest = () => ({
  type: CREATE_DOCUMENT_REQUEST
})

export const createDocumentSuccess = (payload) => ({
  type: CREATE_DOCUMENT_SUCCESS,
  payload,
})

export const createDocumentError = (payload) => ({
  type: CREATE_DOCUMENT_ERROR,
  payload,
})


export const deleteDocumentRequest = (payload) => ({
  type: DELETE_DOCUMENT_REQUEST,
  payload,
})

export const deleteDocumentSuccess = () => ({
  type: DELETE_DOCUMENT_SUCCESS,
})

export const deleteDocumentError = (payload) => ({
  type: DELETE_DOCUMENT_ERROR,
  payload,
})


export const currentDocumentRequest = (payload) => ({
  type: GET_CURRENT_DOCUMENT_REQUEST,
  payload,
})

export const currentDocumentSuccess = (payload) => ({
  type: GET_CURRENT_DOCUMENT_SUCCESS,
  payload,
})

export const currentDocumentError = (payload) => ({
  type: GET_CURRENT_DOCUMENT_ERROR,
  payload,
})


export const updateDocumentTitleRequest = (payload) => ({
  type: UPDATE_DOCUMENT_TITLE_REQUEST,
  payload
})

export const updateDocumentTitleSuccess = () => ({
  type: UPDATE_DOCUMENT_TITLE_SUCCESS
})

export const updateDocumentTitleError = (payload) => ({
  type: UPDATE_DOCUMENT_TITLE_ERROR,
  payload
})


export const updateDocumentRequest = (payload) => ({
  type: UPDATE_DOCUMENT_REQUEST,
  payload,
})

export const updateDocumentSuccess = () => ({
  type: UPDATE_DOCUMENT_SUCCESS,
})

export const updateDocumentError = (payload) => ({
  type: UPDATE_DOCUMENT_ERROR,
  payload,
})


export const downloadPdfDocumentRequest = (payload) => ({
  type: DOWNLOAD_PDF_DOCUMENT_REQUEST,
  payload
})

export const downloadPdfDocumentSuccess = (payload) => ({
  type: DOWNLOAD_PDF_DOCUMENT_SUCCESS,
  payload
})

export const downloadPdfDocumentError = (payload) => ({
  type: DOWNLOAD_PDF_DOCUMENT_ERROR,
  payload
})


export const downloadDocDocumentRequest = (payload) => ({
  type: DOWNLOAD_DOC_DOCUMENT_REQUEST,
  payload
})

export const downloadDocDocumentSuccess = (payload) => ({
  type: DOWNLOAD_DOC_DOCUMENT_SUCCESS,
  payload
})

export const downloadDocDocumentError = (payload) => ({
  type: DOWNLOAD_DOC_DOCUMENT_ERROR,
  payload
})


export const downloadTxtRequest = (payload) => ({
  type: DOWNLOAD_TXT_REQUEST,
  payload
})

export const downloadTxtSuccess = (payload) => ({
  type: DOWNLOAD_TXT_SUCCESS,
  payload
})

export const downloadTxtError = (payload) => ({
  type: DOWNLOAD_TXT_ERROR,
  payload
})
