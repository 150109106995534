import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';

import "./Index.scss"

function LanguageDropdown({ items = [], defaultSelected = items[0], onChangeItem }) {

  const dropdownRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected || {});

  const { id = 0, languageName = '', languageFlag = '' } = selectedOption || {};

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleOptionClick(option) {
    if (option !== selectedOption) {
      setSelectedOption(option);
      setIsOpen(false);
      onChangeItem(option)
    }
  }

  return (
    <div className="drop-down" ref={dropdownRef}>
      <div className="drop-down-header" onClick={toggleDropdown}>
        <div className="drop-down-selected"><img src={languageFlag} alt={languageName} /></div>
        <CaretUpOutlined className="drop-down-arrow" />
      </div>
      {isOpen &&
        <div className="drop-down-container">
          {items?.map((item) => (
            <div className={classNames('drop-down-container-items', {'selected-item': item.id === id})} key={item.id} onClick={() => handleOptionClick(item)}>
              {item.languageName}
              <img src={item.languageFlag} alt={item.languageName} />
            </div>
          ))}
        </div>
      }
    </div>
  );
}

export default LanguageDropdown;