import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png"
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authLoginRequest, authRegisterRequest } from "../../redux/Auth/actions";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import { isAuth } from "../../redux/Auth/saga";
import usePrevious from "../../hooks/usePrevious";

import AuthErrorMessage from "../AuthErrorMessage/Index";
import LoaderButton from "../LoaderButton/Index";

import "./Index.scss"

function Login() {

  const {
    isAuthLoginSuccess,
    isAuthLoginError,
    authLoginErrorMessage,
    isAuthRegisterSuccess,
    isAuthRegisterError,
    authRegisterErrorMessage,
    isConfirmResetPasswordSuccess,
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onTouched',
  });

  const password = watch('password');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevIsConfirmResetPasswordSuccess = usePrevious(isConfirmResetPasswordSuccess);
  const prevIsAuthLoginSuccess = usePrevious(isAuthLoginSuccess);
  const prevIsAuthRegisterSuccess = usePrevious(isAuthRegisterSuccess);
  const prevIsAuthRegisterError = usePrevious(isAuthRegisterError);
  const prevIsAuthLoginError = usePrevious(isAuthLoginError);

  const [tab, setTab] = useState(0);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const [isForgotPasswordShown, setIsForgotPasswordShown] = useState(false);
  const [isVerifyEmailShown, setIsVerifyEmailShown] = useState(false);
  const [isRegisterPressed, setIsRegisterPressed] = useState(false);
  const [allData, setAllData] = useState({});
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isLoginLoader, setIsLoginLoader] = useState(false)
  const [isRegisterLoader, setIsRegisterLoader] = useState(false)

  useEffect(() => {
    if (isAuthRegisterSuccess && isRegisterPressed && prevIsAuthRegisterSuccess === false) {
      setRegisterErrorMessage('');
      setIsVerifyEmailShown(true);
      setIsRegisterLoader(false);
    }
  }, [isAuthRegisterSuccess, isRegisterPressed])

  useEffect(() => {
    if (isAuthLoginSuccess && prevIsAuthLoginSuccess === false) {
      setLoginErrorMessage('');
      setIsLoginLoader(false);
      navigate("/")
    }
  }, [isAuthLoginSuccess])

  useEffect(() => {
    if (isConfirmResetPasswordSuccess && prevIsConfirmResetPasswordSuccess === false) {
      setTimeout(() => {
        handleToggleForgotPassword(false);
      }, 2500)
    }
  }, [isConfirmResetPasswordSuccess])

  useEffect(() => {
    if (isAuthRegisterError && prevIsAuthRegisterError === false) {
      setRegisterErrorMessage(authRegisterErrorMessage);
      setIsRegisterLoader(false);
    }
  }, [isAuthRegisterError])

  useEffect(() => {
    if (isAuthLoginError && prevIsAuthLoginError === false) {
      setLoginErrorMessage(authLoginErrorMessage);
      setIsLoginLoader(false);
    }
  }, [isAuthLoginError])

  function changeTab(change) {
    setTab(change);
    reset();
    setIsPasswordShown(false);
  }

  function checkActiveTab(index) {
    const classes = ['tabs'];
    if (index === tab) {
      classes.push('active-tab')
    }
    return classes.join(' ');
  }

  async function getLocation(data) {
    let location = 'N/A';

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
          const locationData = await response.json();
          location = `${locationData.address.country} / ${locationData.address.city || locationData.address.village}`;
        } catch (error) {
          console.error('Error fetching location information:', error);
        }
        dispatch(authLoginRequest({...data, location}));
        setIsLoginLoader(true);
      }, error => {
        console.error('Error getting user location:', error);
        dispatch(authLoginRequest({...data, location}));
        setIsLoginLoader(true);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
      dispatch(authLoginRequest({...data, location}));
      setIsLoginLoader(true);
    }
  }

  function handleTogglePassword() {
    setIsPasswordShown(!isPasswordShown);
  }

  function handleToggleConfirmPassword() {
    setIsConfirmPasswordShown(!isConfirmPasswordShown);
  }

  function handleToggleForgotPassword(isForgotPassword) {
    setIsForgotPasswordShown(isForgotPassword)
  }

  function registerSubmit(data) {
    delete data.confirmPassword;
    dispatch(authRegisterRequest(data))
    setIsRegisterPressed(true)
    setAllData(data);
    setIsRegisterLoader(true);
  }

  function loginSubmit(data) {
    getLocation(data);
  }

  return(
    <div className="login">
      {isAuth() ?
        <Navigate to="/workspace" /> :
        <>
          <div className="logo">
            <NavLink to="/">
              <img src={Logo} alt="Talk2Edit"/>
            </NavLink>
          </div>
          <div className="container">
            <div className="content">
              { isForgotPasswordShown ?
                <ResetPassword backToLogin={handleToggleForgotPassword} /> :
                isVerifyEmailShown ?
                  <VerifyEmail {...allData} /> :
                  <div key="login">
                    <div className="tab-group">
                      <div className={checkActiveTab(0)} onClick={() => changeTab(0)}>Sign in</div>
                      <div className={checkActiveTab(1)} onClick={() => changeTab(1)}>Sign up</div>
                    </div>
                    <div className="login-title">
                      <h3>{tab ? 'Sign up' : 'Sign in'}</h3>
                    </div>
                    <div className="input-group">
                      { tab ?
                        <div key="signup">
                          <form onSubmit={handleSubmit(registerSubmit)}>
                            <div className="inputs">
                              <input
                                {...register('full_name', {
                                  required: 'Full name is required',
                                  minLength: {
                                    value: 1,
                                    message: 'Minimum required length is 1'
                                  },
                                  maxLength: {
                                    value: 32,
                                    message: 'Maximum required length is 32'
                                  }
                                })}
                                type="text"
                                placeholder="Full name"
                                className={errors?.full_name ? 'input-error' : ''}
                              />
                              { errors?.full_name && <div className="error-message">{errors?.full_name?.message || 'Field is required'}</div> }
                            </div>
                            <div className="inputs">
                              <input
                                {...register('email', {
                                  required: 'Email is required',
                                  pattern: {
                                    value: /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Invalid email address'
                                  },
                                  maxLength: {
                                    value: 60,
                                    message: 'Maximum required length is 60'
                                  }
                                })}
                                type="text"
                                placeholder="Email"
                                className={errors?.email ? 'input-error' : ''}
                              />
                              { errors?.email && <div className="error-message">{errors?.email?.message || 'Field is required'}</div> }
                            </div>
                            <div className="inputs">
                              <div className="password-input">
                                <input
                                  {...register('password',{
                                    required: 'Password is required',
                                    pattern: {
                                      value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{8,}$/,
                                      message: 'Password should include at least one letter, one numeric value and one special character'
                                    },
                                    minLength: {
                                      value: 8,
                                      message: 'Minimum required length is 8'
                                    },
                                    maxLength: {
                                      value: 32,
                                      message: 'Maximum required length is 32'
                                    }
                                  })}
                                  type={isPasswordShown ? 'text' : 'password'}
                                  placeholder="Password"
                                  className={errors?.password ? 'input-error' : ''}
                                />
                                <div className="eye-icon" onClick={handleTogglePassword}>
                                  {isPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
                                </div>
                              </div>
                              { errors?.password && <div className="error-message">{errors?.password?.message || 'Field is required'}</div> }
                            </div>
                            <div className="inputs">
                              <div className="password-input">
                                <input
                                  {...register('confirmPassword',{
                                    required: 'Confirm Password is required',
                                    validate: (value) =>
                                      value === password || 'The passwords do not match'
                                  })}
                                  type={isConfirmPasswordShown ? 'text' : 'password'}
                                  placeholder="Confirm password"
                                  onPaste={(e) => {
                                    e.preventDefault()
                                    return false;
                                  }}
                                  className={errors?.confirmPassword ? 'input-error' : ''}
                                />
                                <div className="eye-icon" onClick={handleToggleConfirmPassword}>
                                  {isConfirmPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
                                </div>
                              </div>
                              { errors?.confirmPassword && <div className="error-message">{errors?.confirmPassword?.message || 'Field is required'}</div> }
                            </div>
                            { registerErrorMessage &&
                                <AuthErrorMessage errorMessage={registerErrorMessage} />
                            }
                            <div className="button">
                              {
                                isRegisterLoader ?
                                  <LoaderButton buttonText="Sign up" /> :
                                  <input type="submit" value="Sign up" />
                              }
                            </div>
                          </form>
                        </div> :
                        <div key="signin">
                          <form onSubmit={handleSubmit(loginSubmit)}>
                            <div className="inputs">
                              <input
                                {...register('email', {
                                  required: 'Email is required',
                                  pattern: {
                                    value: /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Invalid email address'
                                  },
                                  maxLength: {
                                    value: 60,
                                    message: 'Maximum required length is 60'
                                  }
                                })}
                                type="text"
                                placeholder="Email"
                                className={errors?.email ? 'input-error' : ''}
                              />
                              { errors?.email && <div className="error-message">{errors?.email?.message || 'Field is required'}</div> }
                            </div>
                            <div className="inputs">
                              <div className="password-input">
                                <input
                                  {...register('password',{
                                    required: 'Password is required',
                                    minLength: {
                                      value: 8,
                                      message: 'Minimum required length is 8'
                                    },
                                    maxLength: {
                                      value: 32,
                                      message: 'Maximum required length is 32'
                                    }
                                  })}
                                  type={isPasswordShown ? 'text' : 'password'}
                                  placeholder="Password"
                                  className={errors?.password ? 'input-error' : ''}
                                />
                                <div className="eye-icon" onClick={handleTogglePassword}>
                                  {isPasswordShown ? <FaEyeSlash size="18" /> : <FaEye size="18" />}
                                </div>
                              </div>
                              { errors?.password && <div className="error-message">{errors?.password?.message || 'Field is required'}</div> }
                            </div>
                            { loginErrorMessage &&
                                <AuthErrorMessage errorMessage={loginErrorMessage} />
                            }
                            <div className="button">
                              {
                                isLoginLoader ?
                                  <LoaderButton buttonText="Sign in" /> :
                                  <input type="submit" value="Sign in" />
                              }
                            </div>
                          </form>
                          <div className="forget">
                            <p onClick={() => handleToggleForgotPassword(true)}>Forgot password?</p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
              }
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Login;
