import {
  RECORD_TRANSCRIBE_EN_REQUEST,
  RECORD_TRANSCRIBE_HY_REQUEST,
  RECORD_TRANSCRIBE_RU_REQUEST,
  RECORD_TRANSCRIBE_ERROR,
  RECORD_TRANSCRIBE_SUCCESS,
} from "./types";

export const recordTranscribeEnRequest = (payload) => ({
  type: RECORD_TRANSCRIBE_EN_REQUEST,
  payload,
})

export const recordTranscribeHyRequest = (payload) => ({
  type: RECORD_TRANSCRIBE_HY_REQUEST,
  payload,
})

export const recordTranscribeRuRequest = (payload) => ({
  type: RECORD_TRANSCRIBE_RU_REQUEST,
  payload,
})

export const recordTranscribeSuccess = (payload) => ({
  type: RECORD_TRANSCRIBE_SUCCESS,
  payload,
})

export const recordTranscribeError = (payload) => ({
  type: RECORD_TRANSCRIBE_ERROR,
  payload,
})
