import React from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";

import "./index.scss";

function Loader({ fullWidth = false }) {

  return (
    <div className={classNames('loader-container', {'full': fullWidth})}>
      <Spin indicator={<LoadingOutlined spin className="loader" />} />
    </div>
  )

}

export default Loader;
