export const GET_DOCUMENTS_LIST_REQUEST = 'GET_DOCUMENTS_LIST_REQUEST'
export const GET_DOCUMENTS_LIST_SUCCESS = 'GET_DOCUMENTS_LIST_SUCCESS'
export const GET_DOCUMENTS_LIST_ERROR = 'GET_DOCUMENTS_LIST_ERROR'
export const RESET_DOCUMENTS_LIST = 'RESET_DOCUMENT_LIST'

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const CREATE_DOCUMENT_ERROR = 'CREATE_DOCUMENT_ERROR'

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR'

export const GET_CURRENT_DOCUMENT_REQUEST = 'GET_CURRENT_DOCUMENT_REQUEST'
export const GET_CURRENT_DOCUMENT_SUCCESS = 'GET_CURRENT_DOCUMENT_SUCCESS'
export const GET_CURRENT_DOCUMENT_ERROR = 'GET_CURRENT_DOCUMENT_ERROR'

export const UPDATE_DOCUMENT_TITLE_REQUEST = 'UPDATE_DOCUMENT_TITLE_REQUEST'
export const UPDATE_DOCUMENT_TITLE_SUCCESS = 'UPDATE_DOCUMENT_TITLE_SUCCESS'
export const UPDATE_DOCUMENT_TITLE_ERROR = 'UPDATE_DOCUMENT_TITLE_ERROR'

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST'
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS'
export const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR'

export const DOWNLOAD_PDF_DOCUMENT_REQUEST = 'DOWNLOAD_PDF_DOCUMENT_REQUEST'
export const DOWNLOAD_PDF_DOCUMENT_SUCCESS = 'DOWNLOAD_PDF_DOCUMENT_SUCCESS'
export const DOWNLOAD_PDF_DOCUMENT_ERROR = 'DOWNLOAD_PDF_DOCUMENT_ERROR'

export const DOWNLOAD_DOC_DOCUMENT_REQUEST = 'DOWNLOAD_DOC_DOCUMENT_REQUEST'
export const DOWNLOAD_DOC_DOCUMENT_SUCCESS = 'DOWNLOAD_DOC_DOCUMENT_SUCCESS'
export const DOWNLOAD_DOC_DOCUMENT_ERROR = 'DOWNLOAD_DOC_DOCUMENT_ERROR'

export const DOWNLOAD_TXT_REQUEST = 'DOWNLOAD_TXT_REQUEST'
export const DOWNLOAD_TXT_SUCCESS = 'DOWNLOAD_TXT_SUCCESS'
export const DOWNLOAD_TXT_ERROR = 'DOWNLOAD_TXT_ERROR'
