import React from "react";

import "./Index.scss"

function TermsConditions() {

  return (
    <div className="terms-conditions">
      <div className="terms-conditions-content">
        <div className="terms-conditions-content-section">
          <h1>Terms and Conditions</h1>
          <h2>Introduction</h2>
          <p>Welcome to our web application. By accessing or using our services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms,
            please do not use our services.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>User Responsibilities</h2>
          <ul>
            <li><span className="terms-conditions-section-bold-text">Account Creation</span>: Users must provide accurate and complete information when registering for an account.
              You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
            <li><span className="terms-conditions-section-bold-text">Compliance</span>: You agree to use our services in compliance with all applicable laws and regulations.</li>
          </ul>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Prohibited Activities</h2>
          <p>You may not engage in any of the following prohibited activities:</p>
          <ul>
            <li>Using the service for any illegal or unauthorized purpose.</li>
            <li>Attempting to gain unauthorized access to any part of the service, other accounts, or any connected systems or networks.</li>
            <li>Distributing malware or harmful code, or engaging in activities that disrupt the normal functioning of the service.</li>
          </ul>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Service Availability</h2>
          <p>We strive to ensure that our web application is available at all times. However, we do not guarantee that the service will be uninterrupted or error-free.
            We reserve the right to modify or discontinue the service at any time without notice.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Intellectual Property</h2>
          <p>All content and materials provided through our web application, including but not limited to text, graphics, logos, and software, are the property of our company or our licensors.
            You may not reproduce, distribute, or create derivative works from any content without our express permission.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Termination Policy</h2>
          <p>We reserve the right to suspend or terminate your access to the service at our discretion, without notice, if we believe that you have violated these Terms and Conditions.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Limitation of Liability</h2>
          <p>To the maximum extent permitted by law, our company shall not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use our services.
            Our liability is limited to the maximum extent permitted by applicable law.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Privacy Policy</h2>
          <p>Your use of our services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy for more details.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Changes to Terms</h2>
          <p>We may update these Terms and Conditions from time to time. We will notify you of any significant changes via email or through the application.
            Your continued use of the service after any changes signifies your acceptance of the new terms.</p>
        </div>
        <div className="terms-conditions-content-section">
          <h2>Contact Us</h2>
          <p>If you have any questions about these Terms and Conditions, please contact us at:</p>
          <ul>
            <li><span className="terms-conditions-section-bold-text">Email</span>: talk2edit.help@gmail.com</li>
            <li><span className="terms-conditions-section-bold-text">Facebook</span>: <a href="https://www.facebook.com/profile.php?id=61562177254598" target="_blank">Facebook page</a></li>
            <li><span className="terms-conditions-section-bold-text">Instagram</span>: <a href="https://www.instagram.com/talk2edit/" target="_blank">Instagram page</a></li>
          </ul>
        </div>
        <div className="terms-conditions-content-section">
          <p>Thank you for using our web application! We look forward to serving you.</p>
        </div>
      </div>
    </div>
  )
}

export default TermsConditions;
