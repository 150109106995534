import React, { useEffect, useState } from "react";
import "./VerifyEmail.scss"
import { useForm } from "react-hook-form";
import { authConfirmRequest, authLoginRequest, authRegisterRequest } from "../../redux/Auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../LoaderButton/Index";
import AuthErrorMessage from "../AuthErrorMessage/Index";
import usePrevious from "../../hooks/usePrevious";

function VerifyEmail({full_name, email, password}) {

  const {
    isAuthConfirmSuccess,
    isAuthConfirmError,
    authConfirmErrorMessage,
    isAuthLoginSuccess
  } = useSelector(state => state.auth)

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onTouched'
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const prevIsAuthLoginSuccess = usePrevious(isAuthLoginSuccess);
  const prevIsAuthConfirmError = usePrevious(isAuthConfirmError);

  const [location, setLocation] = useState('N/A');
  const [verifyErrorMessage, setVerifyErrorMessage] = useState('');
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (isAuthConfirmSuccess) {
      dispatch(authLoginRequest({email, password, location}))
      setVerifyErrorMessage('');
    }
  }, [isAuthConfirmSuccess])

  useEffect(() => {
    if (isAuthLoginSuccess && prevIsAuthLoginSuccess === false) {
      setIsLoader(false);
      navigate("/")
    }
  }, [isAuthLoginSuccess])

  useEffect(() => {
    if (isAuthConfirmError && prevIsAuthConfirmError === false) {
      setVerifyErrorMessage(authConfirmErrorMessage)
      setIsLoader(false);
    }
  }, [isAuthConfirmError])

  async function getLocation(data) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
          const locationData = await response.json();
          setLocation(`${locationData.address.country} / ${locationData.address.city || locationData.address.village}`);
        } catch (error) {
          console.error('Error fetching location information:', error);
        }
        dispatch(authConfirmRequest({...data, email}));
        setIsLoader(true);
      }, error => {
        console.error('Error getting user location:', error);
        setIsLoader(true);
        dispatch(authConfirmRequest({...data, email}));
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
      setIsLoader(true);
      dispatch(authConfirmRequest({...data, email}));
    }
  }

  function handleResendCode() {
    const obj = {full_name, email, password}
    dispatch(authRegisterRequest(obj))
    reset();
  }

  function handleVerifyCode(data) {
    getLocation(data);
  }

  return (
    <div key="verify-email">
      <div className="top">
        <h3>Verify your email</h3>
      </div>
      <div className="text">
        {`The confirmation code has been sent successfully to email ${email}. Please enter the 6-digit code to proceed.`}
      </div>
      <form onSubmit={handleSubmit(handleVerifyCode)}>
        <div className="inputs">
          <input
            {...register('confirmation_code', {
              required: true,
              minLength: {
                value: 6,
                message: 'The length should be exactly 6'
              },
              maxLength: {
                value: 6,
                message: 'The length should be exactly 6'
              },
            })}
            type="text"
            placeholder="Verification code"
            className={errors?.confirmation_code ? 'input-error' : ''}
            autoComplete="off"
          />
          { errors?.confirmation_code && <div className="error-message">{errors?.confirmation_code?.message || 'Field is required'}</div> }
        </div>
        {
          verifyErrorMessage &&
            <AuthErrorMessage errorMessage={verifyErrorMessage} />
        }
        <div className="button">
          {
            isLoader ?
              <LoaderButton buttonText="Verify code" /> :
              <input type="submit" value="Verify code" />
          }
        </div>
      </form>
      <div className="resend">
        <p onClick={handleResendCode}>Resend the code</p>
      </div>
    </div>
  )
}

export default VerifyEmail;
